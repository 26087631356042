import {
  Form,
  FormRow,
  ReadOnlyField,
  Space,
  Divider,
  Body,
  FormSection,
  Dialog,
} from "design-system";
import {
  useFormatMessage,
  useFormatMoneyAmountValue,
  useFormatPercentageLike,
} from "../../intl";
import { unsafeNonNegativeInteger, foldTenant } from "../../globalDomain";
import { option } from "fp-ts";
import { GenericLoanResponseOutput } from "../api";
import { OfferDetails } from "./CustomerOfferConfigurator";
import { constNull, pipe, constant } from "fp-ts/function";
import { useAppContext } from "../../useAppContext";

type Props = {
  onDismiss: () => unknown;
  customerLoanOffer: GenericLoanResponseOutput;
  offerDetails: OfferDetails;
};

export function CustomerOfferDetailsDialog({
  onDismiss,
  customerLoanOffer,
  offerDetails,
}: Props) {
  const formatMessage = useFormatMessage();
  const formatPercentage = useFormatPercentageLike();
  const {
    apiParameters: { tenant },
    config: { enableBankFee50FE: enableBankFee50 },
  } = useAppContext();

  const formatMoneyAmountValueInteger = useFormatMoneyAmountValue(
    unsafeNonNegativeInteger(0)
  );
  const formatMoneyAmountValue2Decimals = useFormatMoneyAmountValue(
    unsafeNonNegativeInteger(2)
  );

  const { genericLoanOffer, refinancing } = offerDetails;

  return (
    <Dialog
      variant="left"
      size="small"
      onDismiss={option.some(onDismiss)}
      title={formatMessage("StandardLoan.CustomerOffer.detailsModal.title")}
      actions={[]}
    >
      <Form>
        <FormSection>
          <FormRow type="1-1">
            <ReadOnlyField
              size="medium"
              label={formatMessage(
                "StandardLoan.CustomerOffer.detailsModal.totalLoanAmount.label"
              )}
              value={pipe(
                {
                  amount: genericLoanOffer.borrowedAmount,
                  currency: customerLoanOffer.currency,
                },
                moneyData =>
                  foldTenant(
                    tenant,
                    () => formatMoneyAmountValue2Decimals(moneyData),
                    () => formatMoneyAmountValueInteger(moneyData)
                  )
              )}
            />
            <ReadOnlyField
              size="medium"
              label={formatMessage(
                "StandardLoan.CustomerOffer.detailsModal.tenor.label"
              )}
              value={formatMessage(
                "StandardLoan.CustomerOffer.detailsModal.tenor.value",
                { months: genericLoanOffer.tenor }
              )}
            />
          </FormRow>
          <FormRow type="1-1">
            <ReadOnlyField
              size="medium"
              label={formatMessage(
                "StandardLoan.CustomerOffer.detailsModal.installment.label"
              )}
              value={pipe(
                {
                  amount: genericLoanOffer.installmentAmount,
                  currency: customerLoanOffer.currency,
                },
                moneyData =>
                  foldTenant(
                    tenant,
                    () => formatMoneyAmountValue2Decimals(moneyData),
                    () => formatMoneyAmountValueInteger(moneyData)
                  )
              )}
            />
            <ReadOnlyField
              size="medium"
              label={formatMessage(
                "StandardLoan.CustomerOffer.detailsModal.CPI.label"
              )}
              value={pipe(
                {
                  amount: pipe(
                    genericLoanOffer.cpimonthlyAmount,
                    option.getOrElse(constant(0))
                  ),
                  currency: customerLoanOffer.currency,
                },
                moneyData =>
                  foldTenant(
                    tenant,
                    () => formatMoneyAmountValue2Decimals(moneyData),
                    () => formatMoneyAmountValueInteger(moneyData)
                  )
              )}
            />
          </FormRow>
          <FormRow type="1-1">
            <ReadOnlyField
              size="medium"
              label={formatMessage(
                "StandardLoan.CustomerOffer.detailsModal.interestRate.label"
              )}
              value={formatMessage(
                "StandardLoan.CustomerOffer.card.interest.value",
                {
                  interestRate: formatPercentage(
                    genericLoanOffer.interestRateDecimal,
                    { minimumFractionDigits: 2 }
                  ),
                }
              )}
            />
            {pipe(
              genericLoanOffer.rspn,
              option.fold(constNull, rspn => (
                <ReadOnlyField
                  size="medium"
                  label={formatMessage(
                    "StandardLoan.CustomerOffer.detailsModal.APR.label"
                  )}
                  value={formatMessage(
                    "StandardLoan.CustomerOffer.detailsModal.APR.value",
                    {
                      apr: formatPercentage(rspn, {
                        minimumFractionDigits: 2,
                      }),
                    }
                  )}
                />
              ))
            )}
          </FormRow>
          <FormRow type="1-1">
            {pipe(
              genericLoanOffer.totalPayableAmount,
              option.fold(constNull, totalPayableAmount => (
                <ReadOnlyField
                  size="medium"
                  label={formatMessage(
                    "StandardLoan.CustomerOffer.detailsModal.total.label"
                  )}
                  value={pipe(
                    {
                      amount: totalPayableAmount,
                      currency: customerLoanOffer.currency,
                    },
                    moneyData =>
                      foldTenant(
                        tenant,
                        () => formatMoneyAmountValue2Decimals(moneyData),
                        () => formatMoneyAmountValueInteger(moneyData)
                      )
                  )}
                />
              ))
            )}

            {pipe(
              genericLoanOffer.bankingFee,
              option.fold(constNull, bankingFee =>
                pipe(
                  customerLoanOffer.bankingFeeIncluded,
                  option.fold(
                    () => (
                      <ReadOnlyField
                        size="medium"
                        label={formatMessage(
                          "StandardLoan.CustomerOffer.detailsModal.bankingFees.label"
                        )}
                        value={formatMoneyAmountValueInteger({
                          amount: bankingFee,
                          currency: customerLoanOffer.currency,
                        })}
                      />
                    ),
                    feeIncluded =>
                      enableBankFee50 ? (
                        <ReadOnlyField
                          size="medium"
                          label={formatMessage(
                            feeIncluded
                              ? "StandardLoan.CustomerOffer.detailsModal.bankingFees.included.label"
                              : "StandardLoan.CustomerOffer.detailsModal.bankingFees.notIncluded.label"
                          )}
                          value={formatMoneyAmountValueInteger({
                            amount: bankingFee,
                            currency: customerLoanOffer.currency,
                          })}
                        />
                      ) : (
                        <ReadOnlyField
                          size="medium"
                          label={formatMessage(
                            "StandardLoan.CustomerOffer.detailsModal.bankingFees.label"
                          )}
                          value={formatMoneyAmountValueInteger({
                            amount: bankingFee,
                            currency: customerLoanOffer.currency,
                          })}
                        />
                      )
                  )
                )
              )
            )}
          </FormRow>
          {refinancing && (
            <>
              <Divider width="100%" />
              <Space units={10} />
              <FormSection>
                <FormRow type="full">
                  <ReadOnlyField
                    size="medium"
                    label={formatMessage(
                      "StandardLoan.CustomerOffer.detailsModal.totalGiven.label"
                    )}
                    value={formatMoneyAmountValueInteger({
                      amount: genericLoanOffer.borrowedAmount,
                      currency: customerLoanOffer.currency,
                    })}
                  />
                </FormRow>
                {pipe(
                  genericLoanOffer.refinancingAmount,
                  option.fold(constNull, refinancingAmount => (
                    <FormRow type="full">
                      <ReadOnlyField
                        size="medium"
                        label={formatMessage(
                          "StandardLoan.CustomerOffer.detailsModal.coverage.label"
                        )}
                        value={formatMoneyAmountValueInteger({
                          amount: refinancingAmount,
                          currency: customerLoanOffer.currency,
                        })}
                      />
                    </FormRow>
                  ))
                )}
                <FormRow type="full">
                  <ReadOnlyField
                    size="medium"
                    label={formatMessage(
                      "StandardLoan.CustomerOffer.detailsModal.noPurpose.label"
                    )}
                    value={formatMoneyAmountValueInteger({
                      amount: customerLoanOffer.amount,
                      currency: customerLoanOffer.currency,
                    })}
                  />
                </FormRow>
                {pipe(
                  genericLoanOffer.bankingFee,
                  option.fold(constNull, bankingFee => (
                    <FormRow type="full">
                      <ReadOnlyField
                        size="medium"
                        label={formatMessage(
                          "StandardLoan.CustomerOffer.detailsModal.bankingFees.label"
                        )}
                        value={formatMoneyAmountValueInteger({
                          amount: bankingFee,
                          currency: customerLoanOffer.currency,
                        })}
                      />
                    </FormRow>
                  ))
                )}
              </FormSection>
            </>
          )}
        </FormSection>
      </Form>
      <Space units={10} />
      <Divider width="100%" />
      <Space units={10} />
      <Body size="small" weight="regular">
        {formatMessage("StandardLoan.CustomerOffer.detailsModal.footer")}
      </Body>
    </Dialog>
  );
}
