import {
  Body,
  ErrorBanner,
  Form,
  FormRow,
  FormSection,
  Stack,
  useForm,
} from "design-system";
import { palette } from "design-system/lib/styleConstants";
import { boolean, option, taskEither } from "fp-ts";
import { constUndefined, pipe, constVoid, constNull } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import {
  foldTenant,
  GenericError,
  unsafeNonNegativeInteger,
} from "../../globalDomain";
import {
  LocaleKey,
  useFormatMessage,
  useFormatMoneyAmountParts,
} from "../../intl";
import { useCommand } from "../../useAPI";
import { useAppContext } from "../../useAppContext";
import { useValidators } from "../../Common/useValidators";
import { YesNoRadioGroupField } from "../../Common/YesNoRadioGroup/YesNoRadioGroupField";
import { ReadMemorandum } from "./ReadMemorandum";
import * as api from "../api";
import { useState } from "react";
import { foldFlowType, StandardLoanFlowType } from "../domain";
import { AuthorizeWithOTP } from "./AuhtorizeWithOTP";
import { Reader } from "fp-ts/Reader";

type ConsentData = {
  PositiveSolusConsent: Option<boolean>;
  TELCOConsent: Option<boolean>;
  SRBIConsent: Option<boolean>;
  NRKIConsent: Option<boolean>;
};

type Props = {
  loanOffer: api.LoanOffer;
  onNext: Reader<api.StartLoanApplicationInput, unknown>;
  flowType: StandardLoanFlowType;
  initialConsent: Option<api.StartLoanApplicationInput>;
};

export function CreditChecksContent(props: Props) {
  const {
    apiParameters: { tenant },
  } = useAppContext();
  const formatMessage = useFormatMessage();
  const formatMoneyAmountParts = useFormatMoneyAmountParts(
    unsafeNonNegativeInteger(0)
  );
  const { definedNoExtract } = useValidators();
  const startLoanApplication = useCommand(api.startLoanApplication);
  const [errorState, setErrorState] = useState<Option<LocaleKey>>(option.none);

  const defaultFalse = (consent: Option<boolean>) =>
    pipe(
      consent,
      option.getOrElse(() => false)
    );

  const initialValues: ConsentData = pipe(
    props.initialConsent,
    option.map(values => ({
      PositiveSolusConsent: option.some(values.solusConsent),
      TELCOConsent: option.some(values.telcoConsent),
      SRBIConsent: option.some(values.srbiConsent),
      NRKIConsent: option.some(values.nrkiConsent),
    })),
    option.getOrElse<ConsentData>(() => ({
      PositiveSolusConsent: option.none,
      TELCOConsent: option.none,
      SRBIConsent: option.none,
      NRKIConsent: option.none,
    }))
  );

  const { fieldProps, handleSubmit } = useForm(
    {
      initialValues: initialValues,
      fieldValidators: () => ({
        PositiveSolusConsent: foldTenant(tenant, constUndefined, () =>
          definedNoExtract<boolean>()
        ),
        TELCOConsent: foldTenant(tenant, constUndefined, () =>
          definedNoExtract<boolean>()
        ),
        SRBIConsent: foldTenant(
          tenant,
          () => definedNoExtract<boolean>(),
          constUndefined
        ),
        NRKIConsent: foldTenant(
          tenant,
          () => definedNoExtract<boolean>(),
          constUndefined
        ),
      }),
    },
    {
      onSubmit: userConsents => {
        const consents = {
          nrkiConsent: defaultFalse(userConsents.NRKIConsent),
          srbiConsent: defaultFalse(userConsents.SRBIConsent),
          telcoConsent: defaultFalse(userConsents.TELCOConsent),
          solusConsent: defaultFalse(userConsents.PositiveSolusConsent),
        };

        return pipe(
          consents,
          startLoanApplication,
          taskEither.chain(result =>
            pipe(
              result.status,
              boolean.fold(
                () =>
                  taskEither.fromIO(() =>
                    setErrorState(option.some("GenericError"))
                  ),
                () => taskEither.fromIO(() => props.onNext(consents))
              )
            )
          ),
          taskEither.orElse(errorResponse =>
            taskEither.fromIO<unknown, unknown>(() =>
              setErrorState(option.some(parseErrorResponse(errorResponse)))
            )
          )
        );
      },
    }
  );

  const renderFormContent = foldTenant(
    tenant,
    () => (
      <FormSection>
        <FormRow type="full">
          <YesNoRadioGroupField
            label={formatMessage(
              "StandardLoan.CreditChecks.step1.SRBIConsentLabel"
            )}
            description={formatMessage(
              "StandardLoan.CreditChecks.step1.SRBIConsentInfo"
            )}
            {...fieldProps("SRBIConsent")}
          />
        </FormRow>
        <FormRow type="full">
          <YesNoRadioGroupField
            label={formatMessage(
              "StandardLoan.CreditChecks.step1.NRKIConsentLabel"
            )}
            description={formatMessage(
              "StandardLoan.CreditChecks.step1.NRKIConsentInfo"
            )}
            {...fieldProps("NRKIConsent")}
          />
        </FormRow>
      </FormSection>
    ),
    () => (
      <FormSection>
        <FormRow type="full">
          <YesNoRadioGroupField
            label={formatMessage(
              "StandardLoan.CreditChecks.step1.PositiveSolusConsentLabel"
            )}
            description={formatMessage(
              "StandardLoan.CreditChecks.step1.PositiveSolusConsentInfo"
            )}
            {...fieldProps("PositiveSolusConsent")}
          />
        </FormRow>
        <FormRow type="full">
          <YesNoRadioGroupField
            label={formatMessage(
              "StandardLoan.CreditChecks.step1.TELCOConsentLabel"
            )}
            description={formatMessage(
              "StandardLoan.CreditChecks.step1.TELCOConsentInfo"
            )}
            {...fieldProps("TELCOConsent")}
          />
        </FormRow>
      </FormSection>
    )
  );

  const renderFormWithSubmitButton = (
    <Form
      submitButton={{
        label: formatMessage("StandardLoan.CreditChecks.step1.button.confirm"),
        action: handleSubmit,
      }}
    >
      {renderFormContent}
    </Form>
  );

  return (
    <Stack column shrink grow units={10}>
      <ReadMemorandum />
      <Body size="medium" weight="regular" color={palette.neutral700}>
        {formatMessage("StandardLoan.CreditChecks.step1.creditRegister", {
          ...formatMoneyAmountParts({
            amount: props.loanOffer.amount,
            currency: props.loanOffer.currency,
          }),
          tenor: props.loanOffer.tenor,
        })}
      </Body>
      <Body size="medium" weight="regular" color={palette.neutral700}>
        {formatMessage("StandardLoan.CreditChecks.step1.confirm.paragraph1")}
      </Body>
      <Body size="medium" weight="regular" color={palette.neutral700}>
        {formatMessage("StandardLoan.CreditChecks.step1.confirm.paragraph2")}
      </Body>

      {pipe(
        props.flowType,
        foldFlowType({
          when3P: () => (
            <>
              <Form>{renderFormContent}</Form>
              <AuthorizeWithOTP
                onFailure={constVoid}
                onSuccess={handleSubmit}
              />
            </>
          ),
          whenInPerson: () => renderFormWithSubmitButton,
          whenTLSAgent: () => renderFormWithSubmitButton,
          whenHomeBanking: () => renderFormWithSubmitButton,
          whenSmartBanking: () => renderFormWithSubmitButton,
          whenPWSRemote: () => renderFormWithSubmitButton,
        })
      )}
      {pipe(
        errorState,
        option.fold(constNull, error => (
          <ErrorBanner children={formatMessage(error)} />
        ))
      )}
    </Stack>
  );
}

function parseErrorResponse(
  apiError: GenericError | api.StartLoanApplicationError
): LocaleKey {
  if ("translationsKey" in apiError && option.isSome(apiError.translationsKey))
    return apiError.translationsKey.value;

  return "GenericError";
}
