import { forwardRef, ComponentProps } from "react";
import { IO } from "fp-ts/IO";
import {
  Button,
  CheckIcon,
  ErrorIcon,
  InlineLoader,
  LocalizedString,
  SpinnerIcon,
  Stack,
} from "design-system";

type ButtonProps = ComponentProps<typeof Button>;

type Props = {
  onClick: IO<unknown>;
  size: NonNullable<ButtonProps["size"]>;
  labels: {
    normal: LocalizedString;
    success: LocalizedString;
    loading: LocalizedString;
    error: LocalizedString;
  };
  type?: ButtonProps["type"];
  disabled?: ButtonProps["disabled"];
  onKeyDown?: ButtonProps["onKeyDown"];
  "data-test-id"?: ButtonProps["data-test-id"];
  variant: "primary" | "secondary" | "text" | "danger";
  inverted?: boolean;
  className?: ButtonProps["className"];
  loadingMessage?: LocalizedString;
  buttonState: ButtonState;
};

export type ButtonState = "normal" | "loading" | "success" | "error";

export const DeferredLoadingButton = forwardRef<HTMLButtonElement, Props>(
  (props, forwardedRef) => {
    const rightContent = (() => {
      switch (props.buttonState) {
        case "normal":
          return null;
        case "loading":
          return <SpinnerIcon size="default" />;
        case "success":
          return <CheckIcon size="default" />;
        case "error":
          return <ErrorIcon size="default" />;
      }
    })();

    const label = (() => {
      switch (props.buttonState) {
        case "normal":
          return props.labels.normal;
        case "loading":
          return props.labels.loading;
        case "success":
          return props.labels.success;
        case "error":
          return props.labels.error;
      }
    })();

    const button = (
      <Button
        ref={forwardedRef}
        type={props.type}
        variant={props.variant}
        size={props.size}
        label={label}
        action={props.onClick}
        rightContent={rightContent}
        loading={props.buttonState !== "normal"}
        disabled={props.disabled}
        data-test-id={props["data-test-id"]}
        onKeyDown={props.onKeyDown}
        inverted={props.inverted}
        className={props.className}
      />
    );

    if (props.loadingMessage) {
      return (
        <Stack column units={4} hAlignContent="right">
          {button}
          <InlineLoader size="small" message={props.loadingMessage} />
        </Stack>
      );
    } else {
      return button;
    }
  }
);
