import * as api from "../api";
import { CloseCircleIcon, MinusIcon, Upselling } from "design-system";
import { pipe } from "fp-ts/function";
import { useFormatMessage } from "../../../intl";
import { array, boolean, option } from "fp-ts";
import { IO } from "fp-ts/IO";

type Props = {
  currentInsuranceType: api.CreditProtectionInsuranceType;
  cpiPackageList: api.CPIPackageList;
  onClick: IO<unknown>;
};

export function CPIBanner(props: Props) {
  const formatMessage = useFormatMessage();

  return (
    <Upselling
      hasError={props.currentInsuranceType === "None"}
      included
      content={pipe(
        props.currentInsuranceType === "None",
        boolean.fold(
          () =>
            pipe(
              props.cpiPackageList.options,
              array.findFirst(
                ({ type }) => type === props.currentInsuranceType
              ),
              option.fold(
                () =>
                  formatMessage(
                    "StandardLoan.CustomerOffer.insuranceNotIncluded"
                  ),
                ({ name }) =>
                  formatMessage(
                    "StandardLoan.CustomerOffer.insuranceIncluded",
                    {
                      insurance: formatMessage(name),
                    }
                  )
              )
            ),
          () => formatMessage("StandardLoan.CustomerOffer.insuranceNotIncluded")
        )
      )}
      icon={pipe(
        props.currentInsuranceType === "None",
        boolean.fold(
          () => MinusIcon,
          () => CloseCircleIcon
        )
      )}
      action={{
        label: pipe(
          props.currentInsuranceType === "None",
          boolean.fold(
            () =>
              formatMessage(
                "StandardLoan.CustomerOffer.insuranceIncludedButton"
              ),
            () =>
              formatMessage(
                "StandardLoan.CustomerOffer.insuranceNotIncludedButton"
              )
          )
        ),
        onClick: props.onClick,
      }}
    />
  );
}
