import {
  Banner,
  bold,
  Box,
  CheckboxField,
  ContentRow,
  Divider,
  fieldIssues,
  FieldProps,
  Heading,
  InlineMessage,
  InputSliderField,
  Loader,
  LocalizedString,
  NonNegative,
  NumberInputStepperField,
  Positive,
  PositiveInteger,
  Space,
  Stack,
  unsafeLocalizedString,
  unsafeNonNegative,
  unsafePositiveInteger,
  useIsMobileLayout,
} from "design-system";
import {
  array,
  boolean,
  either,
  nonEmptyArray,
  option,
  taskEither,
} from "fp-ts";
import {
  constant,
  constFalse,
  constNull,
  constTrue,
  constVoid,
  flow,
  identity,
  pipe,
} from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { IO } from "fp-ts/IO";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  LocaleKey,
  useFormatCurrency,
  useFormatMessage,
  useFormatMoneyAmount,
  useFormatMoneyAmountParts,
} from "../../intl";
import { CustomerOfferDetailsDialog } from "./CustomerOfferDetailsDialog";
import {
  Currency,
  ExistingClientAuthenticationMethod,
  foldCurrency,
  unsafeNonNegativeInteger,
} from "../../globalDomain";
import * as api from "../api";
import { Reader } from "fp-ts/Reader";
import { useCommand } from "../../useAPI";
import { CustomerOfferCard } from "./CustomerOfferCard";
import { LimitWarning, useOfferLimitsInformation } from "./LimitWarning";
import { StandardLoanFlowType } from "../domain";
import { YourOffer } from "./YourOffer";
import { SelectedLoansInformation } from "../Refinancing/RefinancingAccountSelection";
import { CPIDialog } from "./CPIDialog/CPIDialog";
import {
  ConfiguratorValues,
  foldAPIStatus,
  useUpdateCustomerOfferContext,
} from "./UpdateCustomerOfferContext";
import { CPIAdditionalQuestions, CPIPackageList } from "./api";
import { EntryFeeRadioGroup } from "./EntryFee";
import { TaskEither } from "fp-ts/TaskEither";
import { useAppContext } from "../../useAppContext";

type Props = {
  flowType: StandardLoanFlowType;
  onNext: IO<unknown>;
  onExit: (isApplicationSaved: boolean) => unknown;
  loanOffer: api.GenericLoanResponseOutput;
  hasRefinancingCredits: boolean;
  allInternalLoanSelected: boolean;
  restoredAdditionalQuestions: Option<CPIAdditionalQuestions>;
  selectedRefinancingCredits: SelectedLoansInformation;
  authenticationMethod: Option<ExistingClientAuthenticationMethod>;
  onUpdateCustomerOffer: (status: api.UpdateOfferStatus) => void;
  cpiPackageList: Option<CPIPackageList>;
};

export type OfferDetails = {
  genericLoanOffer: api.GenericLoanOffer;
  refinancing: boolean;
};

interface ClampAndRoundParams {
  min: number;
  max: number;
  step: Positive;
}

function makeClampAndRound(
  params: ClampAndRoundParams
): Reader<number, number> {
  return amount => {
    // handle edge cases on max and min
    if (amount === params.min || amount === params.max) return amount;
    const rounded = Math.round(amount / params.step) * params.step;
    return Math.max(Math.min(rounded, params.max), params.min);
  };
}

function useDebounceAction<I>(
  action: Reader<I, void>,
  delay: number
): Reader<I, void> {
  const timeoutRef = useRef<Option<number>>(option.none);

  return (input: I) => {
    pipe(
      timeoutRef.current,
      option.fold(constVoid, currentTimeout => {
        window.clearTimeout(currentTimeout);
      })
    );

    timeoutRef.current = option.some(
      window.setTimeout(() => {
        action(input);
        timeoutRef.current = option.none;
      }, delay)
    );
  };
}

export function CustomerOfferConfigurator(props: Props) {
  const {
    preapprovedLimitState,
    handleUpdateCustomerOffer,
    loanOfferState,
  } = useUpdateCustomerOfferContext();
  const {
    config: { enableBankFee50FE: enableBankFee50 },
  } = useAppContext();

  const [, updateState] = useState<unknown>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [bankingFeeValid, setBankingFeeValid] = useState(true);
  const isMobileLayout = useIsMobileLayout();

  const [offerDetails, setOfferDetails] = useState<Option<OfferDetails>>(
    option.none
  );

  const [currentLoanOffer, setCurrentLoanOffer] = useState<
    api.GenericLoanResponseOutput & {
      isLoading: boolean;
    }
  >({
    ...props.loanOffer,
    isLoading: false,
  });

  //we should use this from BE/PCE
  const amountStep = option.isSome(currentLoanOffer.stepSizeAmount)
    ? currentLoanOffer.stepSizeAmount.value
    : pipe(
        currentLoanOffer.currency,
        foldCurrency({
          CZK: () => 5000,
          EUR: () => 200,
        }),
        unsafePositiveInteger
      );

  const tenorStep = option.isSome(currentLoanOffer.stepSizeTenorMonths)
    ? currentLoanOffer.stepSizeTenorMonths.value
    : unsafePositiveInteger(3);

  const saveSelectedCustomerOffer = useCommand(api.saveSelectedCustomerOffer);
  const formatMessage = useFormatMessage();
  const formatMoneyAmount = useFormatMoneyAmount(unsafeNonNegativeInteger(0));
  const formatMoneyAmountParts = useFormatMoneyAmountParts(
    unsafeNonNegativeInteger(0)
  );
  const formatCurrency = useFormatCurrency();
  const [isCalculating, setIsCalculating] = useState(false);

  const getGenericLoanOffer = (fullOffer: api.GenericLoanResponseOutput) =>
    pipe(
      fullOffer.genericLoanOffers,
      option.map(
        offers =>
          offers[
            currentLoanOffer.salaryTransfer
              ? "SALARY_TRANSFER_OFFER"
              : "NO_SALARY_TRANSFER_OFFER"
          ]
      )
    );

  const genericLoanOffer = getGenericLoanOffer(currentLoanOffer);
  const fetchedGenericLoanOffer = getGenericLoanOffer(props.loanOffer);

  const standardOfferValues = genericLoanOffer;
  const benefitsOfferValues = pipe(
    currentLoanOffer.genericLoanOffers,
    option.map(offers => offers.SALARY_TRANSFER_OFFER)
  );

  const clampAndRoundAmount = makeClampAndRound({
    min: currentLoanOffer.minAmount,
    max: currentLoanOffer.maxAmount,
    step: amountStep,
  });

  const clampAndRoundTenor = makeClampAndRound({
    min: currentLoanOffer.minTenor,
    max: currentLoanOffer.maxTenor,
    step: tenorStep,
  });

  function formatLabelContent(
    currency: Currency,
    message: LocaleKey
  ): Reader<number, LocalizedString> {
    return value =>
      formatMessage(
        message,
        formatMoneyAmount(
          { amount: unsafeNonNegative(value), currency },
          identity
        )
      );
  }

  const updateCustomerOfferNow = (values: ConfiguratorValues) => {
    setCurrentLoanOffer(offer => ({
      ...offer,
      isLoading: true,
    }));

    const updateCustomerOfferValues: ConfiguratorValues = {
      amount: pipe(
        values.amount,
        clampAndRoundAmount,
        NonNegative.decode,
        either.getOrElse(() => currentLoanOffer.minAmount)
      ),
      tenor: pipe(
        values.tenor,
        clampAndRoundTenor,
        PositiveInteger.decode,
        either.getOrElse(() => currentLoanOffer.maxTenor)
      ),
      installmentDay: values.installmentDay,
      salaryTransfer: values.salaryTransfer,
      bankingFeeIncluded: values.bankingFeeIncluded,
    };

    const updateCustomerOfferCallBack = (
      updatedOffer: Option<api.GenericLoanResponseOutput>
    ) => {
      pipe(
        updatedOffer,
        option.fold(
          () =>
            setCurrentLoanOffer(prevOffer => ({
              ...prevOffer,
              isLoading: false,
            })),
          offer =>
            setCurrentLoanOffer({
              ...values,
              ...offer,
              isLoading: false,
            })
        )
      );
    };

    handleUpdateCustomerOffer(updateCustomerOfferValues, ({ genericLoan }) => {
      setIsCalculating(false);
      updateCustomerOfferCallBack(genericLoan);
    });
  };

  const debounceUpdate = useDebounceAction(updateCustomerOfferNow, 1000);

  const onAmountUpdate: Reader<NonNegative, void> = amount => {
    setCurrentLoanOffer(offer => ({
      ...offer,
      amount,
    }));

    setIsCalculating(true);
    updateCustomerOfferNow({
      ...currentLoanOffer,
      amount,
      installmentDay: pipe(
        currentLoanOffer.installmentDay,
        option.getOrElse(() => unsafePositiveInteger(0))
      ),
    });
  };

  const onTenorUpdate: Reader<PositiveInteger, void> = tenor => {
    setCurrentLoanOffer(offer => ({
      ...offer,
      tenor,
    }));

    setIsCalculating(true);
    updateCustomerOfferNow({
      ...currentLoanOffer,
      tenor,
      installmentDay: pipe(
        currentLoanOffer.installmentDay,
        option.getOrElse(() => unsafePositiveInteger(0))
      ),
    });
  };

  const onSalaryTransferUpdate: Reader<boolean, void> = salaryTransfer => {
    setCurrentLoanOffer(offer => ({
      ...offer,
      salaryTransfer,
    }));

    setIsCalculating(true);
    updateCustomerOfferNow({
      ...currentLoanOffer,
      salaryTransfer,
      installmentDay: pipe(
        currentLoanOffer.installmentDay,
        option.getOrElse(() => unsafePositiveInteger(0))
      ),
    });
  };

  const onInstallmentDayUpdate: Reader<
    PositiveInteger,
    void
  > = installmentDay => {
    setCurrentLoanOffer(offer => ({
      ...offer,
      installmentDay: option.some(installmentDay),
    }));

    setIsCalculating(true);
    debounceUpdate({
      ...currentLoanOffer,
      installmentDay,
    });
  };

  const onEntryFeeOptionChange: Reader<Option<boolean>, void> = feeIncluded => {
    setBankingFeeValid(true);
    setCurrentLoanOffer(offer => ({
      ...offer,
      bankingFeeIncluded: feeIncluded,
    }));

    setIsCalculating(true);
    updateCustomerOfferNow({
      ...currentLoanOffer,
      installmentDay: pipe(
        currentLoanOffer.installmentDay,
        option.getOrElse(() => unsafePositiveInteger(0))
      ),
      bankingFeeIncluded: feeIncluded,
    });
  };

  const onCpiInitialState = () => {
    setShowCPIList({
      showCPIList: false,
      salaryTransfer: false,
    });
  };

  const onCpiChange = () => {
    setCurrentLoanOffer(offer => ({
      ...offer,
      isLoading: true,
    }));

    handleUpdateCustomerOffer(
      {
        ...currentLoanOffer,
        installmentDay: pipe(
          currentLoanOffer.installmentDay,
          option.getOrElse(() => unsafePositiveInteger(0))
        ),
        bankingFeeIncluded: currentLoanOffer.bankingFeeIncluded,
      },
      ({ genericLoan, updateOfferStatus }) => {
        setIsCalculating(false);
        pipe(
          genericLoan,
          option.fold(
            () =>
              setCurrentLoanOffer(prevOffer => ({
                ...prevOffer,
                isLoading: false,
              })),
            offer => setCurrentLoanOffer({ ...offer, isLoading: false })
          )
        );
        props.onUpdateCustomerOffer(updateOfferStatus);
      }
    );
  };

  const productType = pipe(
    props.loanOffer.productType,
    option.fold(
      () => "",
      productType => productType
    )
  );

  const isTopUpActive =
    option.isSome(props.authenticationMethod) && productType === "TL";

  const offerLimitsInformation = useOfferLimitsInformation(
    props.flowType,
    props.loanOffer,
    pipe(
      props.loanOffer.wayOfIdentification,
      option.getOrElse<api.WayOfIdentification>(() => "UNKNOWN")
    ),
    preapprovedLimitState,
    isTopUpActive,
    props.allInternalLoanSelected,
    props.selectedRefinancingCredits
  );

  const hasSelectedRefinancingCredits =
    props.selectedRefinancingCredits.internal.count +
      props.selectedRefinancingCredits.external.count >
    0;

  const [showCPIList, setShowCPIList] = useState({
    showCPIList: false,
    salaryTransfer: false,
  });

  const hasSelectedInsurance = () => {
    const r = pipe(
      currentLoanOffer.insurance,
      option.fold(constFalse, a => a !== "None")
    );
    return r;
  };

  const cpiEligibleByAge = pipe(
    props.loanOffer.cpiEligibleByAge,
    option.fold(constTrue, a => a)
  );
  const onChooseOffer = (
    cbk: TaskEither<unknown, unknown>
  ): TaskEither<unknown, unknown> => {
    const validateForm = enableBankFee50
      ? pipe(
          currentLoanOffer.bankingFee,
          option.fold(constTrue, fee =>
            fee === 0
              ? true
              : pipe(currentLoanOffer.bankingFeeIncluded, option.isSome)
          )
        )
      : true;

    return validateForm
      ? cbk
      : taskEither.leftIO(() => {
          entryFeeProps.issues = option.some(
            fieldIssues.errors([formatMessage("Form.fieldError.required")])
          );

          setBankingFeeValid(false);

          entryFeeRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });

          forceUpdate();
        });
  };

  const entryFeeProps: FieldProps<Option<boolean>> = {
    label: unsafeLocalizedString(""),
    onBlur: constVoid,
    value: currentLoanOffer.bankingFeeIncluded,
    issues: option.none,
    name: unsafeLocalizedString(""),
    onChange: onEntryFeeOptionChange,
  };

  const benefitsOfferCard = pipe(
    benefitsOfferValues,
    option.fold(constant(<></>), offer => (
      <CustomerOfferCard
        {...offerLimitsInformation}
        title={formatMessage("StandardLoan.CustomerOffer.benefitsOffer.title")}
        recommended
        amount={offer.monthlyPayment}
        tenor={offer.tenor}
        interestRate={offer.interestRateDecimal}
        onViewDetails={() => {
          setOfferDetails(
            option.some({
              genericLoanOffer: offer,
              refinancing:
                props.hasRefinancingCredits && hasSelectedRefinancingCredits,
            })
          );
        }}
        onChooseOffer={onChooseOffer(
          hasSelectedInsurance() || !cpiEligibleByAge
            ? pipe(
                saveSelectedCustomerOffer({
                  salaryTransferOfferKey: "SALARY_TRANSFER_OFFER",
                }),
                taskEither.chain(() => taskEither.fromIO(props.onNext))
              )
            : taskEither.fromIO(() =>
                setShowCPIList({
                  showCPIList: true,
                  salaryTransfer: true,
                })
              )
        )}
        currency={currentLoanOffer.currency}
        showDisclaimer={true}
        insuranceFee={offer.cpimonthlyAmount}
      />
    ))
  );

  const standardOfferCard = pipe(
    currentLoanOffer.salaryTransfer ? benefitsOfferValues : standardOfferValues,
    option.fold(constant(<></>), offer => (
      <CustomerOfferCard
        {...offerLimitsInformation}
        title={formatMessage("StandardLoan.CustomerOffer.standardOffer.title")}
        recommended={false}
        amount={offer.monthlyPayment}
        tenor={offer.tenor}
        interestRate={offer.interestRateDecimal}
        onViewDetails={() => {
          setOfferDetails(
            option.some({
              genericLoanOffer: offer,
              refinancing:
                props.hasRefinancingCredits && hasSelectedRefinancingCredits,
            })
          );
        }}
        onChooseOffer={onChooseOffer(
          hasSelectedInsurance() || !cpiEligibleByAge
            ? pipe(
                saveSelectedCustomerOffer({
                  salaryTransferOfferKey: currentLoanOffer.salaryTransfer
                    ? "SALARY_TRANSFER_OFFER"
                    : "NO_SALARY_TRANSFER_OFFER",
                }),
                taskEither.chain(() => taskEither.fromIO(props.onNext))
              )
            : taskEither.fromIO(() =>
                setShowCPIList({
                  showCPIList: true,
                  salaryTransfer: currentLoanOffer.salaryTransfer,
                })
              )
        )}
        currency={currentLoanOffer.currency}
        showDisclaimer={false}
        insuranceFee={offer.cpimonthlyAmount}
      />
    ))
  );

  const installmentDayConst = pipe(
    currentLoanOffer.installmentDayList,
    option.fold(
      () => ({ min: 5, max: 25 }),
      installmentDayList => ({
        min: pipe(installmentDayList, nonEmptyArray.head),
        max: pipe(installmentDayList, nonEmptyArray.last),
      })
    )
  );

  const renderOffers = isMobileLayout ? (
    <Stack column units={8}>
      {benefitsOfferCard}
      {standardOfferCard}
    </Stack>
  ) : (
    <ContentRow type="full">
      <>
        {benefitsOfferCard}
        <Space units={8} />
        {standardOfferCard}
      </>
    </ContentRow>
  );

  const preApprovedBanner = pipe(
    isTopUpActive,
    boolean.fold(
      () => offerLimitsInformation.preapprovedLimit,
      () => offerLimitsInformation.topUpLimit
    ),
    option.fold(constNull, limit => (
      <Banner
        type="informative"
        title={option.none}
        content={formatMessage(
          "StandardLoan.CustomerOffer.preApprovedBannerMessage",
          formatMoneyAmount(limit, identity)
        )}
        actions={option.none}
        onDismiss={option.none}
      />
    ))
  );

  useEffect(() => {
    pipe(
      loanOfferState,
      foldAPIStatus({
        whenError: constVoid,
        whenLoading: constVoid,
        whenSuccess: data =>
          setCurrentLoanOffer({ ...data.genericLoan, isLoading: false }),
      })
    );
  }, [loanOfferState]);

  const entryFeeRef = useRef<HTMLElement>(null);
  const renderEntryFee = enableBankFee50
    ? pipe(
        currentLoanOffer.bankingFee,
        option.fold(constNull, entryFee =>
          entryFee == 0 ? null : (
            <EntryFeeRadioGroup
              {...entryFeeProps}
              ref={entryFeeRef}
              entryFee={{
                feeIncluded: currentLoanOffer.bankingFeeIncluded,
                amount: entryFee,
              }}
              disabled={isCalculating}
              issues={
                bankingFeeValid
                  ? option.none
                  : option.some(
                      fieldIssues.errors([
                        formatMessage("Form.fieldError.required"),
                      ])
                    )
              }
            />
          )
        )
      )
    : null;

  const [showLoading, setShowLoading] = useState(false);

  return showLoading ? (
    <Box hAlignContent="center">
      <Loader />
    </Box>
  ) : (
    <Stack column units={8}>
      <Heading size="small" weight="medium">
        {formatMessage("StandardLoan.CustomerOffer.LoanConfiguration.title")}
      </Heading>

      <>
        {pipe(
          props.loanOffer.eligibleForPreapproved,
          option.fold(
            () => preApprovedBanner,
            eligibleForPreapproved =>
              eligibleForPreapproved === 1 && preApprovedBanner
          )
        )}
      </>

      <InputSliderField
        value={props.loanOffer.amount}
        onChange={val =>
          pipe(isNaN(val) ? 0 : val, flow(unsafeNonNegative, onAmountUpdate))
        }
        min={props.loanOffer.minAmount}
        max={props.loanOffer.maxAmount}
        step={amountStep}
        label={
          hasSelectedRefinancingCredits
            ? formatMessage("StandardLoan.CustomerOffer.amountLabelRefinancing")
            : formatMessage("StandardLoan.CustomerOffer.amountLabel")
        }
        minLabelContent={formatLabelContent(
          props.loanOffer.currency,
          "StandardLoan.CustomerOffer.minAmountLabel"
        )}
        maxLabelContent={formatLabelContent(
          props.loanOffer.currency,
          "StandardLoan.CustomerOffer.maxAmountLabel"
        )}
        name="amount"
        issues={option.none}
        onBlur={constVoid}
        rightContent={formatCurrency(props.loanOffer.currency)}
        disabled={isCalculating}
        locale="sk"
      />
      <LimitWarning
        {...offerLimitsInformation}
        currency={currentLoanOffer.currency}
        loanOffer={props.loanOffer}
        onExit={props.onExit}
      />
      <InputSliderField
        value={currentLoanOffer.tenor}
        onChange={flow(
          PositiveInteger.decode,
          either.getOrElse(() => unsafePositiveInteger(1)),
          onTenorUpdate
        )}
        min={currentLoanOffer.minTenor}
        max={currentLoanOffer.maxTenor}
        step={tenorStep}
        minLabelContent={() =>
          formatMessage("StandardLoan.CustomerOffer.minTenorLabel", {
            months: currentLoanOffer.minTenor,
          })
        }
        maxLabelContent={() =>
          formatMessage("StandardLoan.CustomerOffer.maxTenorLabel", {
            months: currentLoanOffer.maxTenor,
          })
        }
        name="currentTenor"
        issues={option.none}
        label={formatMessage("StandardLoan.CustomerOffer.tenorLabel")}
        onBlur={constVoid}
        rightContent={formatMessage("StandardLoan.CustomerOffer.tenorUnits")}
        disabled={isCalculating}
      />
      <>
        {pipe(
          pipe(
            props.loanOffer.displayMinTenorInfoLabel,
            option.fold(
              () => false,
              displayMinTenorInfoLabel => displayMinTenorInfoLabel
            )
          ),
          boolean.fold(constNull, () =>
            pipe(
              offerLimitsInformation.moneyAmountLimit,
              option.fold(constNull, moneyAmountLimit =>
                pipe(
                  props.loanOffer.minTenorForPreapproved,
                  option.fold(constNull, minTenorForPreapproved => {
                    return (
                      <InlineMessage
                        type="warning"
                        message={formatMessage(
                          "StandardLoan.CustomerOffer.info.preapprovedLimit.minTenor",
                          {
                            ...formatMoneyAmountParts({
                              amount: moneyAmountLimit.amount,
                              currency: moneyAmountLimit.currency,
                            }),
                            minTenorForPreapproved: minTenorForPreapproved,
                          }
                        )}
                        size="medium"
                      />
                    );
                  })
                )
              )
            )
          )
        )}
      </>
      <Box column>
        <CheckboxField
          name="salaryTransfer"
          value={currentLoanOffer.salaryTransfer}
          onChange={onSalaryTransferUpdate}
          onBlur={constVoid}
          label={bold(
            formatMessage("StandardLoan.CustomerOffer.transferYourSalary")
          )}
          issues={option.none}
        />

        <Space units={2} />

        <InlineMessage
          type="informative"
          message={formatMessage(
            "StandardLoan.CustomerOffer.transferYourSalaryMessage"
          )}
          size="medium"
          noIcon
        />
      </Box>

      <NumberInputStepperField
        onBlur={constVoid}
        name="dayOfInstallment"
        label={formatMessage(
          "StandardLoan.CustomerOffer.dayOfInstallmentLabel",
          {
            min: installmentDayConst.min,
            max: installmentDayConst.max,
          }
        )}
        value={currentLoanOffer.installmentDay}
        onChange={flow(
          option.map(PositiveInteger.decode),
          option.fold(constVoid, either.fold(constVoid, onInstallmentDayUpdate))
        )}
        min={installmentDayConst.min}
        max={installmentDayConst.max}
        issues={pipe(
          currentLoanOffer.installmentDayList,
          option.chain(installmentDayList =>
            pipe(
              currentLoanOffer.installmentDay,
              option.chain(installmentDay =>
                pipe(
                  installmentDayList,
                  array.findFirst(e => e === installmentDay),
                  option.fold(
                    () =>
                      option.some(
                        fieldIssues.errors([
                          formatMessage(
                            "StandardLoan.CustomerOffer.dayOfInstallment.validationError",
                            {
                              installmentDay,
                            }
                          ),
                        ])
                      ),
                    () => option.none
                  )
                )
              )
            )
          )
        )}
      />
      <>
        {pipe(
          props.cpiPackageList,
          option.fold(constNull, cpiPackageList => (
            <CPIDialog
              currentOfferInstallment={{
                amount: currentLoanOffer.installment,
                currency: currentLoanOffer.currency,
              }}
              restoredAdditionalQuestions={props.restoredAdditionalQuestions}
              onCpiChange={onCpiChange}
              onCpiInitialState={onCpiInitialState}
              cpiPackageList={cpiPackageList}
              noSelection={showCPIList.showCPIList}
              onNoCPIWanted={pipe(
                taskEither.fromIO(() => setShowLoading(true)),
                taskEither.chain(() =>
                  saveSelectedCustomerOffer({
                    salaryTransferOfferKey: showCPIList.salaryTransfer
                      ? "SALARY_TRANSFER_OFFER"
                      : "NO_SALARY_TRANSFER_OFFER",
                  })
                ),
                taskEither.chain(() => taskEither.fromIO(props.onNext))
              )}
            />
          ))
        )}
      </>
      {renderEntryFee}
      <Divider width="100%" />
      <>
        {pipe(
          fetchedGenericLoanOffer,
          option.alt(() => genericLoanOffer),
          option.fold(constNull, loanOffer => (
            <YourOffer
              currentLoanOffer={currentLoanOffer}
              genericLoanOffer={loanOffer}
            />
          ))
        )}
      </>
      <Heading size="medium" weight="medium">
        {formatMessage(
          currentLoanOffer.salaryTransfer
            ? "StandardLoan.CustomerOffer.selectOption"
            : "StandardLoan.CustomerOffer.selectOption.not.transfer"
        )}
      </Heading>

      {currentLoanOffer.salaryTransfer ? (
        <ContentRow type="full">{standardOfferCard}</ContentRow>
      ) : (
        renderOffers
      )}
      <>
        {pipe(
          offerDetails,
          option.fold(constNull, details => (
            <CustomerOfferDetailsDialog
              onDismiss={() => setOfferDetails(option.none)}
              customerLoanOffer={currentLoanOffer}
              offerDetails={details}
            />
          ))
        )}
      </>
    </Stack>
  );
}
