import {
  Box,
  Space,
  Card,
  LocalizedString,
  ReadOnlyField,
  FormSection,
  FormRow,
  Body,
  Stack,
  Button,
  LoadingButton,
  NonNegative,
  PositiveInteger,
  useIsMobileLayout,
  InlineMessage,
  Badge,
} from "design-system";
import {
  Currency,
  MoneyAmount,
  unsafeNonNegativeInteger,
} from "../../globalDomain";
import {
  useFormatMessage,
  useFormatMoneyAmount,
  useFormatPercentageLike,
} from "../../intl";
import { ReadOnlyMoneyAmountField } from "../../Common/ReadOnlyMoneyAmountField/ReadOnlyMoneyAmountField";
import { IO } from "fp-ts/IO";
import { TaskEither } from "fp-ts/TaskEither";
import { Option } from "fp-ts/Option";
import { RemoteLimitTooltip } from "./RemoteLimitTooltip";
import { constNull, identity, pipe } from "fp-ts/function";
import { option } from "fp-ts";

type Props = {
  title: LocalizedString;
  recommended: boolean;
  amount: Option<NonNegative>;
  tenor: PositiveInteger;
  interestRate: number;
  onViewDetails: IO<unknown>;
  onChooseOffer: TaskEither<unknown, unknown>;
  currency: Currency;
  isAmountOverLimit: Option<boolean>;
  preapprovedLimit: Option<MoneyAmount>;
  showDisclaimer: boolean;
  insuranceFee: Option<NonNegative>;
};

export function CustomerOfferCard(props: Props) {
  const formatMessage = useFormatMessage();
  const formatPercentage = useFormatPercentageLike();
  const isMobileLayout = useIsMobileLayout();
  const formatMoneyAmount = useFormatMoneyAmount();

  const renderButton = (disabled: boolean) => (
    <LoadingButton
      variant="primary"
      labels={{
        normal: formatMessage("StandardLoan.CustomerOffer.chooseOffer"),
        error: formatMessage("StandardLoan.CustomerOffer.chooseOffer"),
        loading: formatMessage("StandardLoan.CustomerOffer.chooseOffer"),
        success: formatMessage("StandardLoan.CustomerOffer.chooseOffer"),
      }}
      action={props.onChooseOffer}
      size="default"
      disabled={disabled}
    />
  );

  return (
    <Card
      grow
      badge={
        props.recommended
          ? {
              variant: "label",
              label: formatMessage(
                "StandardLoan.CustomerOffer.card.recommended"
              ),
            }
          : undefined
      }
    >
      <Box column grow shrink>
        <Body size="big" weight="medium">
          {props.title}
        </Body>
        {props.showDisclaimer && (
          <InlineMessage
            size="big"
            type="warning"
            message={formatMessage(
              "StandardLoan.CustomerOffer.benefitsOffer.disclaimer"
            )}
          />
        )}
        <Stack column grow units={2}>
          <FormSection>
            <Space fluid />
            {pipe(
              props.amount,
              option.fold(constNull, amount =>
                pipe(
                  props.insuranceFee,
                  option.fold(
                    () => (
                      <FormRow type="full">
                        <ReadOnlyMoneyAmountField
                          size="big"
                          label={formatMessage(
                            "StandardLoan.CustomerOffer.card.paidMonthly"
                          )}
                          fractionDigits={unsafeNonNegativeInteger(2)}
                          value={{ amount, currency: props.currency }}
                        />
                      </FormRow>
                    ),
                    insuranceFee => (
                      <FormRow type="1-1" vAlignContent="top">
                        <ReadOnlyMoneyAmountField
                          size="big"
                          label={formatMessage(
                            "StandardLoan.CustomerOffer.card.paidMonthly"
                          )}
                          fractionDigits={unsafeNonNegativeInteger(2)}
                          value={{ amount, currency: props.currency }}
                        />
                        <Box hAlignContent="right" column>
                          <Space units={6} />
                          <Badge
                            variant="default"
                            size="small"
                            textAlignment="right"
                            label={formatMessage(
                              "StandardLoan.CustomerOffer.card.insuranceFee",
                              {
                                insuranceFee: formatMessage(
                                  "MoneyAmount",
                                  formatMoneyAmount(
                                    {
                                      amount: insuranceFee,
                                      currency: props.currency,
                                    },
                                    identity
                                  )
                                ),
                              }
                            )}
                          />
                        </Box>
                      </FormRow>
                    )
                  )
                )
              )
            )}
            <FormRow type="full">
              <ReadOnlyField
                size="medium"
                label={formatMessage(
                  "StandardLoan.CustomerOffer.card.duration.label"
                )}
                value={formatMessage("numMonths", { num: props.tenor })}
              />
            </FormRow>
            <FormRow type="full">
              <ReadOnlyField
                size="medium"
                label={formatMessage(
                  "StandardLoan.CustomerOffer.card.interest.label"
                )}
                value={formatMessage(
                  "StandardLoan.CustomerOffer.card.interest.value",
                  {
                    interestRate: formatPercentage(props.interestRate, {
                      minimumFractionDigits: 2,
                    }),
                  }
                )}
              />
            </FormRow>
          </FormSection>
          <Space units={4} />
          <Stack
            units={8}
            hAlignContent={isMobileLayout ? undefined : "right"}
            column={isMobileLayout}
          >
            <Button
              variant="secondary"
              label={formatMessage("StandardLoan.CustomerOffer.viewDetails")}
              action={props.onViewDetails}
              size="default"
            />
            {pipe(
              props.preapprovedLimit,
              option.fold(
                () => (
                  <RemoteLimitTooltip
                    isAmountOverLimit={props.isAmountOverLimit}
                    renderChildren={renderButton}
                  />
                ),
                () => renderButton(false)
              )
            )}
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
}
