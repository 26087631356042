import { useState } from "react";
import { taskEither } from "fp-ts";
import { pipe } from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { Body, buttonLink } from "design-system";
import { useFormatMessage } from "../../intl";
import { useAppContext } from "../../useAppContext";
import { useCommand } from "../../useAPI";
import * as documentAPI from "../../Common/documentAPI";
import { Docset } from "../../Common/documentAPI";
import { FilePDFDialog } from "../../Common/Dialogs/FilePDFDialog/FilePDFDialog";
import { palette } from "design-system/lib/styleConstants";

export function ReadMemorandum() {
  const formatMessage = useFormatMessage();
  const {
    apiParameters: { tenant },
  } = useAppContext();

  const [memorandumModalOpen, setMemorandumModalOpen] = useState(false);

  const memorandumConsentTemplates = useCommand(documentAPI.templates);
  const memorandumConsentContent = useCommand(documentAPI.content);

  const docsets = ((): NonEmptyArray<Docset> => {
    switch (tenant) {
      case "CZ":
        return ["CLcreditBureauMemorandum"];
      case "SK":
        return ["creditBureauMemorandum"];
    }
  })();

  return (
    <>
      <Body size="medium" weight="regular" color={palette.neutral700}>
        {[
          formatMessage("StandardLoan.CreditChecks.step1.memorandum"),
          buttonLink(
            () => setMemorandumModalOpen(true),
            formatMessage("StandardLoan.CreditChecks.step1.readMemorandumLink")
          ),
          formatMessage(
            "StandardLoan.CreditChecks.step1.readMemorandumLinkFollow"
          ),
        ]}
      </Body>
      {memorandumModalOpen && (
        <FilePDFDialog
          onDismiss={() => setMemorandumModalOpen(false)}
          title={formatMessage("StandardLoan.CreditChecks.readMemorandumTitle")}
          file={pipe(
            memorandumConsentTemplates({
              docsets,
            }),
            taskEither.chain(items =>
              memorandumConsentContent({ docId: items[0].docId })
            )
          )}
        />
      )}
    </>
  );
}
