import { usePollingEffect, useQuery } from "../../useAPI";
import * as api from "../api";
import { IncomeAndPersonalDataForm } from "./IncomeAndPersonalDataForm";
import { Box, ErrorBanner, FeedbackDialog, Loader, Omit } from "design-system";
import { useFormatMessage } from "../../intl";
import { Reader } from "fp-ts/Reader";
import { ComponentProps, useState } from "react";
import { constant, constVoid, pipe } from "fp-ts/function";
import { array, boolean, option } from "fp-ts";
import * as remoteData from "../../RemoteData";
import { ReworkOldValuesOutput } from "../Rework/api";
import { Option } from "fp-ts/Option";
import { PersonalDataFromOutput } from "../Confirmation/domain";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { useAppContext } from "../../useAppContext";
import { IncomeSourceType } from "../IncomeForm/domain";
import { RejectionReason } from "../StandardLoanState";

type Props = {
  onApplicationRejected: (rejectionReason: RejectionReason) => unknown;
  onNext: Reader<
    {
      personalDataOptions: api.PersonalDataOptionsApprovedOutput;
      needsAdditionalIncome: boolean;
      hasAdditionalIncome: boolean;
      hasCounterOffer?: boolean;
    },
    unknown
  >;
  getIncome: boolean;
  reworkData: Option<ReworkOldValuesOutput>;
  restoredData: Option<api.IncomeAndPersonalData>;
  restoredPersonalData: Option<PersonalDataFromOutput>;
  reworkAll: boolean;
  onExit: Option<() => unknown>;
  hasPreapprovedLimits?: boolean;
  onChangeIncomeSourceType: (value: Option<IncomeSourceType>) => void;
};

type State =
  | { type: "Polling" }
  | { type: "AttemptsExceeded" }
  | { type: "Error" }
  | { type: "Done"; personalData: api.PersonalDataOptionsApprovedOutput };

export function IncomeAndPersonalDataFormWrapper(
  props: Omit<
    ComponentProps<typeof IncomeAndPersonalDataForm>,
    "hasPreapprovedLimits"
  >
) {
  const [income] = useQuery(api.getIncome);
  return pipe(
    income,
    remoteData.fold(
      () => <Loader />,
      () => (
        <IncomeAndPersonalDataForm
          {...props}
          income={option.none}
          hasPreapprovedLimits={false}
        />
      ),
      value => (
        <IncomeAndPersonalDataForm
          {...props}
          income={option.some(value.income)}
          hasPreapprovedLimits={value.income.hasPreapprovedLimits}
        />
      )
    )
  );
}

export type LTVDecision = "OK" | "NOK" | "PENDING";

export function IncomeAndPersonalData(props: Props) {
  const formatMessage = useFormatMessage();
  const [state, setState] = useState<State>({ type: "Polling" });
  const [ltvDecision, setLTVDecision] = useState<LTVDecision>("PENDING");
  const [displayErrorDialog, setDisplayErrorDialog] = useState<boolean>(false);

  const {
    apiParameters: { tenant },
  } = useAppContext();

  usePollingEffect(api.getPersonalDataOptions, {
    intervalMS: 6000,
    shouldPollingContinue: ({ status }) =>
      status === "RETRY" && state.type != "Error",
    onError: () => setState({ type: "Error" }),
    onSuccess: data => {
      switch (data.status) {
        case "ATTEMPTS_EXCEEDED":
          return setState({ type: "Error" });
        case "RETRY":
          return setState({ type: "Polling" });
        case "OK":
          switch (data.result) {
            case "CBRejected":
              return props.onApplicationRejected("CBRejected");
            case "Approved":
              setState({ type: "Done", personalData: data });
          }
      }
    },
  });

  usePollingEffect(api.checkLtvWithinLimits, {
    intervalMS: 10000,
    disabled: tenant !== "CZ" || displayErrorDialog,
    shouldPollingContinue: response => response.status === "PENDING",
    onError: () => setLTVDecision("PENDING"),
    onSuccess: response => {
      if (!displayErrorDialog) {
        setLTVDecision(response.status);
        if (response.status === "NOK") {
          setDisplayErrorDialog(true);
        }
      }
    },
  });

  switch (state.type) {
    case "Polling":
      return (
        <Box grow hAlignContent="center">
          <Loader />
        </Box>
      );
    case "Error":
      return <ErrorBanner children={formatMessage("GenericError")} />;
    case "AttemptsExceeded":
      return <ErrorBanner children={formatMessage("GenericError")} />;
    case "Done":
      const incomeAndPersonalDataProps = {
        options: state.personalData,
        onNext: (
          needsAdditionalIncome: boolean,
          hasAdditionalIncome: boolean,
          hasCounterOffer?: boolean
        ) =>
          props.onNext({
            personalDataOptions: state.personalData,
            needsAdditionalIncome: needsAdditionalIncome,
            hasAdditionalIncome: hasAdditionalIncome,
            hasCounterOffer: hasCounterOffer,
          }),
        income: option.none,
        reworkData: props.reworkData,
        personalData: option.none,
        onApplicationRejected: props.onApplicationRejected,
        reworkAll: props.reworkAll,
        onLTVStatusError: (ltvDecision: LTVDecision) => {
          setLTVDecision(ltvDecision);
          setDisplayErrorDialog(true);
        },
      };

      const renderedIncomeAndPersonalData = pipe(
        props.restoredData,
        option.fold(
          () =>
            pipe(
              props.getIncome,
              boolean.fold(
                () => (
                  <IncomeAndPersonalDataForm
                    {...incomeAndPersonalDataProps}
                    hasPreapprovedLimits={props.hasPreapprovedLimits === true}
                    onChangeIncomeSourceType={props.onChangeIncomeSourceType}
                  />
                ),
                () => (
                  <IncomeAndPersonalDataFormWrapper
                    {...incomeAndPersonalDataProps}
                    onChangeIncomeSourceType={props.onChangeIncomeSourceType}
                  />
                )
              )
            ),
          restoredData => (
            <IncomeAndPersonalDataForm
              {...incomeAndPersonalDataProps}
              income={option.some(restoredData.income)}
              hasPreapprovedLimits={props.hasPreapprovedLimits === true}
              personalData={pipe(
                restoredData.personalData,
                option.map(personalData =>
                  pipe(
                    personalData.personalData,
                    array.map(v =>
                      pipe(
                        props.restoredPersonalData,
                        option.chain(rpd =>
                          pipe(
                            rpd,
                            array.findFirstMap(r =>
                              r.key === v.key
                                ? (r.options as Option<NonEmptyArray<any>>)
                                : option.none
                            )
                          )
                        ),
                        option.fold(constant(v), k => ({
                          ...v,
                          options: option.some(k),
                        }))
                      )
                    )
                  )
                )
              )}
              onChangeIncomeSourceType={props.onChangeIncomeSourceType}
            />
          )
        )
      );

      return (
        <>
          {displayErrorDialog && ltvDecision === "NOK" && (
            <FeedbackDialog
              type="negative"
              title={formatMessage("LTVWithinLimits.OutsideLimits.title")}
              subtitle={formatMessage("LTVWithinLimits.OutsideLimits.subtitle")}
              cta={{
                label: formatMessage("LTVWithinLimits.OutsideLimits.action"),
                action: pipe(
                  props.onExit,
                  option.getOrElse(() => constVoid)
                ),
              }}
            />
          )}
          {displayErrorDialog && ltvDecision === "PENDING" && (
            <FeedbackDialog
              type="warning"
              title={formatMessage("LTVWithinLimits.NoResponse.title")}
              cta={{
                label: formatMessage("LTVWithinLimits.NoResponse.action"),
                action: pipe(
                  props.onExit,
                  option.getOrElse(() => constVoid)
                ),
              }}
            />
          )}
          {renderedIncomeAndPersonalData}
        </>
      );
  }
}
