import {
  CheckIcon,
  FeatureList,
  Space,
  Badge,
  Box,
  SelectionCard,
  Heading,
  LoadingButton,
  useIsMobileLayout,
  Stack,
  AngleUpIcon,
  AngleDownIcon,
  Banner,
} from "design-system";
import { array, boolean, nonEmptyArray, option, taskEither } from "fp-ts";
import { pipe, constNull, identity } from "fp-ts/function";
import {
  foldTenant,
  MoneyAmount,
  unsafeNonNegativeInteger,
} from "../../../globalDomain";
import { useFormatMessage, useFormatMoneyAmount } from "../../../intl";
import { CreditProtectionInsurance } from "../api";
import { DisplayMoneyAmount } from "../../../Common/Price/DisplayMoneyAmount";
import { useAppContext } from "../../../useAppContext";
import { TaskEither } from "fp-ts/TaskEither";
import * as classes from "./CoverageCard.treat";
import { useState } from "react";
import cx from "classnames";

type Props = {
  creditProtectionInsurance: Pick<
    CreditProtectionInsurance,
    "name" | "features" | "price"
  >;
  onSelectInsurance: TaskEither<unknown, unknown>;
  onKeepInsurance?: TaskEither<unknown, unknown>;
  selected: boolean;
  installment: MoneyAmount;
  disabled: boolean;
  isSuggested: boolean;
  isExpanded?: boolean;
};

export function CoverageCard(props: Props) {
  const formatMessage = useFormatMessage();
  const formatMoneyAmount = useFormatMoneyAmount();
  const insuranceName = formatMessage(props.creditProtectionInsurance.name);
  const isMobileLayout = useIsMobileLayout();
  const [isCollapsed, setIsCollapsed] = useState(!props.isExpanded);

  const {
    apiParameters: { tenant },
  } = useAppContext();

  const ctaLabel = pipe(
    props.selected,
    boolean.fold(
      () =>
        formatMessage(
          "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.chooseButton",
          {
            coverage: insuranceName,
          }
        ),
      () =>
        formatMessage(
          "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.keepButton",
          {
            coverage: insuranceName,
          }
        )
    )
  );

  const isContentVisible = !isMobileLayout || (isMobileLayout && !isCollapsed);

  const variant = props.disabled
    ? "disabled"
    : props.selected
    ? "primary"
    : "secondary";

  return (
    <SelectionCard
      variant={variant}
      grow
      data-test-id={insuranceName}
      badge={
        props.selected
          ? {
              variant: "label",
              label: formatMessage(
                "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.selectedCoverageBadge"
              ),
            }
          : props.isSuggested
          ? {
              variant: "label",
              label: formatMessage(
                "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.standardCoverageBadge"
              ),
            }
          : props.disabled
          ? {
              variant: "label",
              label: formatMessage(
                "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.notAvailable"
              ),
            }
          : undefined
      }
    >
      <Box grow shrink column className={classes.container} width="0px">
        <Stack
          units={2}
          vAlignContent="center"
          onClick={() => setIsCollapsed(!isCollapsed)}
          className={cx({ [classes.disabled]: props.disabled })}
        >
          <Heading
            size="small"
            weight="medium"
            className={cx({ [classes.disabled]: props.disabled })}
          >
            {insuranceName}
          </Heading>
          {isMobileLayout &&
            (isCollapsed ? (
              <AngleDownIcon
                size="small"
                className={cx(classes.angleIcon, {
                  [classes.disabled]: props.disabled,
                })}
              />
            ) : (
              <AngleUpIcon
                size="small"
                className={cx(classes.angleIcon, {
                  [classes.disabled]: props.disabled,
                })}
              />
            ))}
        </Stack>
        <Space units={3} />
        {isContentVisible &&
          pipe(
            props.creditProtectionInsurance.features,
            array.map(feature => ({
              label: formatMessage(feature),
              icon: CheckIcon,
            })),
            nonEmptyArray.fromArray,
            option.fold(constNull, features => (
              <FeatureList
                size="medium"
                children={features}
                disabled={props.disabled}
              />
            ))
          )}
        <Space units={5} />

        <Space fluid />

        <Stack units={8} column>
          {props.selected && (
            <Banner
              type="informative"
              title={option.some(
                formatMessage(
                  "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.alreadyIncludedTitle"
                )
              )}
              content={formatMessage(
                "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.alreadyIncludedDescription"
              )}
              onDismiss={option.none}
              actions={option.none}
            />
          )}
          <Box vAlignContent="center">
            <Box grow vAlignContent="center">
              <Box grow>
                <DisplayMoneyAmount
                  size="big"
                  vAlignContent="center"
                  amount={props.creditProtectionInsurance.price}
                  fractionDigits={unsafeNonNegativeInteger(
                    foldTenant(
                      tenant,
                      () => 2,
                      () => 0
                    )
                  )}
                  className={cx({ [classes.disabled]: props.disabled })}
                />
              </Box>
              <Box className={classes.monthlyInstallmentTagContainer}>
                <Badge
                  variant={props.disabled ? "disabled" : "default"}
                  size="small"
                  textAlignment="right"
                  label={formatMessage(
                    "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.monthlyInstallment",
                    {
                      installment: formatMessage(
                        "MoneyAmount",
                        formatMoneyAmount(
                          {
                            amount:
                              props.creditProtectionInsurance.price.amount +
                              props.installment.amount,
                            currency:
                              props.creditProtectionInsurance.price.currency,
                          },
                          identity
                        )
                      ),
                    }
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Stack>

        <Space units={4} />
        {props.disabled
          ? !isMobileLayout && <Space units={12} />
          : isContentVisible && (
              <Box column>
                <LoadingButton
                  variant="secondary"
                  size="default"
                  labels={{
                    normal: ctaLabel,
                    success: ctaLabel,
                    loading: ctaLabel,
                    error: ctaLabel,
                  }}
                  action={pipe(
                    props.selected,
                    boolean.fold(
                      () => props.onSelectInsurance,
                      () =>
                        pipe(
                          props.onKeepInsurance,
                          option.fromNullable,
                          option.map(taskEither.fromIO),
                          option.getOrElse(() =>
                            taskEither.fromIO<unknown, unknown>(
                              props.onSelectInsurance
                            )
                          )
                        )
                    )
                  )}
                />
              </Box>
            )}
      </Box>
    </SelectionCard>
  );
}
