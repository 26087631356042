import {
  CheckboxField,
  ComputedFieldProps,
  DateField,
  DropdownOption,
  ErrorBanner,
  FormSection,
  LocalizedString,
  NumberField,
  TextField,
  unsafeLocalizedString,
  useForm,
  Validator,
} from "design-system";
import { array, boolean, option, record, taskEither } from "fp-ts";
import {
  constFalse,
  constTrue,
  constUndefined,
  constVoid,
  pipe,
} from "fp-ts/function";
import { IO } from "fp-ts/IO";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { Option } from "fp-ts/Option";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { useEffect, useMemo, useState } from "react";
import { RuntimeLocaleKey, useFormatDate, useFormatMessage } from "../../intl";
import { CompanySuggestion } from "../../Common/CompanyField/domain";
import { useCompanyFields } from "../../Common/CompanyField/utils";
import { DropdownField } from "../../Common/DropdownField/DropdownField";
import {
  dropdownOptionToValue,
  selectedDropdownOption,
} from "../../Common/selectDropdownOption";
import { useValidators } from "../../Common/useValidators";
import { PromoForm } from "../../PhoneAndEmailVerification/PromoForm";
import {
  foldPersonalDataField,
  GenericPersonalDataField,
  PersonalData,
  PersonalDataField,
  PersonalDataFromOutput,
  PersonalDataOption,
} from "../Confirmation/domain";
import { useReworkPersonalDataComparator } from "../Rework/useReworkComparator";
import { useQuery } from "../../useAPI";
import { promoCodeRead } from "../../PhoneAndEmailVerification/api";
import * as remoteData from "../../RemoteData";
import { useAppContext } from "../../useAppContext";
import { foldTenant, MaritalStatus } from "../../globalDomain";

type Props = {
  personalData: PersonalData;
  canSubmit: boolean;
  onDataReady: ReaderTaskEither<PersonalData, unknown, unknown>;
  onFailure: IO<unknown>;
  reworkData: Option<PersonalDataFromOutput>;
};

export function PersonalDataForm(props: Props) {
  const {
    apiParameters: { tenant },
  } = useAppContext();
  const formatMessage = useFormatMessage();
  const formatDate = useFormatDate("dd-mm-yyyy");
  const [promoCodeQueryResult] = useQuery(promoCodeRead);

  const { formatIssues, isDisabled } = useReworkPersonalDataComparator(
    props.reworkData
  );

  const {
    nonBlankString,
    validDate,
    defined,
    maxLength,
    validCompanyIcoRequired,
    nonNegativeNumber,
    minAmountValidator,
  } = useValidators();

  const CompanyFields = useCompanyFields();
  const [isOkPromoCodeForm, setIsOkPromoCodeForm] = useState<boolean>(true);
  const [showCodeInputError, setShowCodeInputError] = useState<boolean>(false);
  const [companyIcoIsValid, setCompanyIcoIsValid] = useState(true);
  const [isMarried, setMarried] = useState(true);

  const isSkTenant = foldTenant(tenant, constTrue, constFalse);

  const { fieldProps, handleSubmit, setValues, values } = useForm(
    {
      initialValues: pipe(
        props.personalData,
        array.reduce<
          PersonalDataField,
          Record<RuntimeLocaleKey, PersonalDataField>
        >({}, (res, field) => ({
          ...res,
          [field.key]: field,
        }))
      ),
      fieldValidators: record.map(
        foldPersonalDataField<
          Validator<PersonalDataField, PersonalDataField> | undefined
        >(
          constUndefined,
          field => () =>
            pipe(
              validDate(option.some(field.value)),
              taskEither.chain(() => taskEither.fromIO(() => field))
            ),
          field => () =>
            pipe(
              defined<number>()(field.value),
              taskEither.chain(nonNegativeNumber),
              taskEither.chain(() => taskEither.fromIO(() => field))
            ),
          field => () =>
            pipe(
              nonBlankString(field.value),
              taskEither.chain(() => taskEither.fromIO(() => field))
            ),
          field => () =>
            isSkTenant && isMarried
              ? pipe(
                  defined<number>()(field.value),
                  taskEither.chain(nonNegativeNumber),
                  taskEither.chain(
                    minAmountValidator(
                      2,
                      formatMessage("FormFieldError.adultsMinAmountWhenMarried")
                    )
                  ),
                  taskEither.chain(() => taskEither.fromIO(() => field))
                )
              : pipe(
                  defined<number>()(field.value),
                  taskEither.chain(nonNegativeNumber),
                  taskEither.chain(() => taskEither.fromIO(() => field))
                ),
          field => () =>
            pipe(
              validCompanyIcoRequired(false, companyIcoIsValid)(field.value),
              taskEither.chain(() => taskEither.fromIO(() => field))
            ),
          field => () =>
            pipe(
              nonBlankString(field.value),
              taskEither.chain(maxLength(200)),
              taskEither.chain(() => taskEither.fromIO(() => field))
            )
        )
      ),
    },
    {
      onSubmit: data => () =>
        pipe(
          isOkPromoCodeForm,
          boolean.fold(
            taskEither.fromIO(() => {
              setShowCodeInputError(true);
              props.onFailure();
            }),
            props.onDataReady(Object.values(data))
          )
        ),
    }
  );

  useEffect(() => {
    setMarried(
      fieldProps("MaritalStatus" as RuntimeLocaleKey) &&
        pipe(
          fieldProps("MaritalStatus" as RuntimeLocaleKey)
            .value as Option<MaritalStatus>,
          option.exists(status => status === "Married")
        )
    );
  }, [fieldProps("MaritalStatus" as RuntimeLocaleKey).value]);

  useEffect(() => {
    // @ts-ignore
    if (values["NumberOfPersonsInHousehold"]) {
      fieldProps("NumberOfPersonsInHousehold" as RuntimeLocaleKey)?.onChange(
        fieldProps("NumberOfPersonsInHousehold" as RuntimeLocaleKey)?.value
      );
    }
  }, [isMarried]);

  useEffect(
    () =>
      pipe(
        props.canSubmit,
        boolean.fold(
          constVoid,
          pipe(
            handleSubmit,
            taskEither.orElse(() => taskEither.fromIO(props.onFailure))
          )
        )
      ),
    [props.canSubmit]
  );

  const [isFormInErrorState, companyICO, companyName] = useMemo(() => {
    let companyICO: Option<PersonalDataField> = option.none;
    let companyName: Option<PersonalDataField> = option.none;
    let errorState = false;

    props.personalData.forEach(field => {
      if (option.isSome(field.autocomplete)) {
        switch (field.autocomplete.value) {
          case "CompanyICO":
            if (option.isNone(companyICO)) {
              companyICO = option.some(field);
            } else {
              console.error(
                `Found multiple autocomplete company ICO field ${field.key}`
              );
              errorState = true;
            }
            break;

          case "CompanyName":
            if (option.isNone(companyName)) {
              companyName = option.some(field);
            } else {
              console.error(
                `Found multiple autocomplete company name field ${field.key}`
              );
              errorState = true;
            }
            break;
        }
      }
    });

    return [errorState, companyICO, companyName];
  }, [props.personalData]);

  const syncCompanyFields = (suggestion: CompanySuggestion) => {
    if (option.isSome(companyICO)) {
      companyICO.value.value = suggestion.id;

      setValues({
        [companyICO.value.key]: companyICO.value,
      });
    }

    if (option.isSome(companyName)) {
      companyName.value.value = suggestion.name;

      setValues({
        [companyName.value.key]: companyName.value,
      });
    }
  };

  function toFieldPropsWithIssues<T, O = T>(
    props: ComputedFieldProps<GenericPersonalDataField<T, O>>
  ): ComputedFieldProps<T> {
    return {
      ...props,
      value: props.value.value,
      onChange: value =>
        props.onChange({
          ...props.value,
          value,
        }),
      issues: formatIssues(props.name, props.issues),
      disabled: props.disabled || isDisabled(props.name),
    };
  }

  const onCompanyIcoValidationChange = (isValid: boolean) => {
    setCompanyIcoIsValid(isValid);
  };

  return pipe(
    isFormInErrorState,
    boolean.fold(
      () => (
        <FormSection>
          {pipe(
            props.personalData,
            array.map(
              foldPersonalDataField(
                field => {
                  const computedFieldProps = toFieldPropsWithIssues(
                    fieldProps(field.key) as ComputedFieldProps<
                      GenericPersonalDataField<boolean>
                    >
                  );

                  return (
                    <CheckboxField
                      label={formatMessage(field.key)}
                      {...computedFieldProps}
                    />
                  );
                },
                field => {
                  const {
                    value,
                    onChange,
                    ...computedFieldProps
                  } = toFieldPropsWithIssues(
                    fieldProps(field.key) as ComputedFieldProps<
                      GenericPersonalDataField<Date>
                    >
                  );

                  return pipe(
                    field.options,
                    option.fold(
                      () => (
                        <DateField
                          label={formatMessage(field.key)}
                          placeholder={formatMessage(field.key)}
                          {...computedFieldProps}
                          value={option.some(option.some(value))}
                          onChange={option.fold(
                            constVoid,
                            option.fold(constVoid, onChange)
                          )}
                        />
                      ),
                      options => {
                        const dropdownOptions = options.map(opt => ({
                          label: pipe(
                            opt.name,
                            option.map(formatMessage),
                            option.getOrElse(() => formatDate(opt.value))
                          ),
                          value: opt.value,
                        }));

                        const dropdownValue = pipe(
                          dropdownOptions,
                          array.findFirst(
                            option =>
                              option.value.toISOString() === value.toISOString()
                          )
                        );

                        return (
                          <DropdownField
                            label={formatMessage(field.key)}
                            placeholder={formatMessage(field.key)}
                            {...computedFieldProps}
                            value={dropdownValue}
                            onChange={val => {
                              pipe(
                                val,
                                dropdownOptionToValue,
                                option.fold(constVoid, onChange)
                              );
                            }}
                            options={dropdownOptions}
                            clearable={false}
                            searchable={false}
                          />
                        );
                      }
                    )
                  );
                },
                field => {
                  const computedFieldProps = toFieldPropsWithIssues(
                    fieldProps(field.key) as ComputedFieldProps<
                      GenericPersonalDataField<Option<number>, number>
                    >
                  );

                  return pipe(
                    field.options as Option<
                      NonEmptyArray<PersonalDataOption<number>>
                    >,
                    option.fold(
                      () => (
                        <NumberField
                          label={formatMessage(field.key)}
                          placeholder={formatMessage(field.key)}
                          {...computedFieldProps}
                          rightContent={option.none}
                        />
                      ),
                      options => {
                        const dropdownOptions: DropdownOption<number>[] = options.map(
                          opt => ({
                            label: pipe(
                              opt.name,
                              option.map(formatMessage),
                              option.getOrElse(() =>
                                unsafeLocalizedString(opt.value.toString(10))
                              )
                            ),
                            value: opt.value,
                          })
                        );

                        return (
                          <DropdownField
                            label={formatMessage(field.key)}
                            placeholder={formatMessage(field.key)}
                            {...computedFieldProps}
                            value={selectedDropdownOption(
                              computedFieldProps.value,
                              dropdownOptions
                            )}
                            onChange={val => {
                              computedFieldProps.onChange(
                                dropdownOptionToValue(val)
                              );
                            }}
                            options={dropdownOptions}
                            clearable={false}
                            searchable={false}
                          />
                        );
                      }
                    )
                  );
                },
                field => {
                  const {
                    value,
                    onChange,
                    ...computedFieldProps
                  } = toFieldPropsWithIssues(
                    fieldProps(field.key) as ComputedFieldProps<
                      GenericPersonalDataField<LocalizedString>
                    >
                  );

                  return pipe(
                    field.options,
                    option.fold(
                      () => (
                        <TextField
                          label={formatMessage(field.key)}
                          placeholder={formatMessage(field.key)}
                          {...computedFieldProps}
                          value={value}
                          onChange={value => onChange(value as LocalizedString)}
                        />
                      ),
                      options => {
                        const dropdownOptions = options.map(opt => ({
                          label: pipe(
                            opt.name,
                            option.map(formatMessage),
                            option.getOrElse(() => opt.value)
                          ),
                          value: opt.value,
                        }));

                        return (
                          <DropdownField
                            label={formatMessage(field.key)}
                            placeholder={formatMessage(field.key)}
                            {...computedFieldProps}
                            value={selectedDropdownOption(
                              option.some(value),
                              dropdownOptions
                            )}
                            onChange={val =>
                              pipe(
                                val,
                                dropdownOptionToValue,
                                option.fold(constVoid, onChange)
                              )
                            }
                            options={dropdownOptions}
                            clearable={false}
                            searchable={false}
                          />
                        );
                      }
                    )
                  );
                },
                field => {
                  const computedFieldProps = toFieldPropsWithIssues(
                    fieldProps(field.key) as ComputedFieldProps<
                      GenericPersonalDataField<Option<number>, number>
                    >
                  );
                  return (
                    <NumberField
                      label={formatMessage(field.key)}
                      placeholder={formatMessage(field.key)}
                      {...computedFieldProps}
                      rightContent={option.none}
                    />
                  );
                },
                field => {
                  const computedFieldProps = toFieldPropsWithIssues(
                    fieldProps(field.key) as ComputedFieldProps<
                      GenericPersonalDataField<string>
                    >
                  );

                  return (
                    <CompanyFields.Name
                      showNativeTooltip
                      {...computedFieldProps}
                      label={formatMessage(field.key)}
                      placeholder={formatMessage(field.key)}
                      onSelectSuggestion={syncCompanyFields}
                      // onValidationChange={onValidationChange}
                    />
                  );
                },
                field => {
                  const computedFieldProps = toFieldPropsWithIssues(
                    fieldProps(field.key) as ComputedFieldProps<
                      GenericPersonalDataField<string>
                    >
                  );

                  return (
                    <CompanyFields.ICO
                      showNativeTooltip
                      {...computedFieldProps}
                      label={formatMessage(field.key)}
                      placeholder={formatMessage(field.key)}
                      onSelectSuggestion={syncCompanyFields}
                      onCompanyIcoValidationChange={
                        onCompanyIcoValidationChange
                      }
                    />
                  );
                }
              )
            )
          )}
          {option.isNone(props.reworkData) &&
            pipe(
              promoCodeQueryResult,
              remoteData.toOption,
              option.map(({ ValidPromotionCode }) =>
                pipe(
                  ValidPromotionCode,
                  option.fold(
                    () => (
                      <PromoForm
                        initialPromoCode={option.none}
                        onApply={constVoid}
                        onRemove={constVoid}
                        onValidFormChange={isValid => {
                          setIsOkPromoCodeForm(isValid);
                          pipe(
                            isValid,
                            boolean.fold(constVoid, () =>
                              setShowCodeInputError(false)
                            )
                          );
                        }}
                        showError={showCodeInputError}
                      />
                    ),
                    ({ promoCode, isCodeConsumed, landingPageText }) => (
                      <PromoForm
                        initialPromoCode={option.some(promoCode)}
                        onApply={constVoid}
                        onRemove={constVoid}
                        readonly={isCodeConsumed}
                        message={landingPageText}
                        onValidFormChange={isValid => {
                          setIsOkPromoCodeForm(isValid);
                          pipe(
                            isValid,
                            boolean.fold(constVoid, () =>
                              setShowCodeInputError(false)
                            )
                          );
                        }}
                        showError={showCodeInputError}
                      />
                    )
                  )
                )
              ),
              option.toNullable
            )}
        </FormSection>
      ),
      () => <ErrorBanner children={formatMessage("GenericError")} />
    )
  );
}
