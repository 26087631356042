import {
  Box,
  Heading,
  LocalizedString,
  NumberedList,
  SmartphoneIcon,
  Stack,
} from "design-system";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { useFormatMessage } from "../intl";
import { SmartKeyMode, SmartKeyVariant } from "./SmartKeyState";

type Props = { mode: SmartKeyMode; variant: SmartKeyVariant };

export function Left(props: Props) {
  const formatMessage = useFormatMessage();
  const titleMessage = ((): LocalizedString => {
    switch (props.variant) {
      case "authorization":
        return formatMessage("SmartKey.title.authorization");
      case "authentication":
        return formatMessage("SmartKey.title.authentication");
    }
  })();

  const instructions: NonEmptyArray<LocalizedString> =
    props.mode === "push"
      ? [
          formatMessage("SmartKey.authorization.firstInstruction"),
          formatMessage(
            "SmartKey.authorization.pushNotificaiton.secondInstruction"
          ),
          formatMessage(
            "SmartKey.authorization.pushNotificaiton.thirdInstruction"
          ),
        ]
      : [
          formatMessage("SmartKey.authorization.firstInstruction"),
          formatMessage("SmartKey.authorization.qrCode.secondInstruction"),
          formatMessage("SmartKey.authorization.qrCode.thirdInstruction"),
          formatMessage("SmartKey.authorization.qrCode.fourthInstruction"),
        ];

  const title = (
    <Stack units={8} column shrink>
      <Stack units={5} vAlignContent="center">
        <SmartphoneIcon size="large" />
        <Heading size="medium" weight="regular">
          {titleMessage}
        </Heading>
      </Stack>
      <Box shrink>
        <NumberedList items={instructions} />
      </Box>
    </Stack>
  );

  return title;
}
