import { PositiveInteger, Body, Stack, LocalizedString } from "design-system";

import { formatDuration, useFormatMessage } from "../intl";
import { usePollingEffect } from "../useAPI";
import * as api from "./api";

import { useCountdown } from "../Common/IdleGuard/useCountdown";
import { Option } from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";

type Props = {
  transactionId: Option<LocalizedString>;
  initialRemainingTimeSeconds: PositiveInteger;
  onExpire: () => void;
  onError: (errorCode: Option<api.ErrorCode>) => void;
  onSuccess: () => void;
  checkPushStatus: api.CheckPushAPI;
};

export function PushNotificationTimer(props: Props) {
  const formatMessage = useFormatMessage();

  usePollingEffect(props.checkPushStatus, {
    intervalMS: 3000,
    shouldPollingContinue: output => output.status === "RETRY",
    onError: () => props.onError(option.none),
    onSuccess: output => {
      switch (output.status) {
        case "OK":
          return props.onSuccess();
        case "CANCEL":
        case "ERR":
          return props.onError(output.errorCode);
      }
    },
  });

  const [remainingTimeSeconds] = useCountdown(
    props.initialRemainingTimeSeconds * 1000,
    props.onExpire
  );

  return (
    <Stack column units={5}>
      <Body size="small" weight="regular">
        {formatMessage("SmartKey.timer", {
          remainingTime: formatDuration(remainingTimeSeconds),
        })}
      </Body>
      {pipe(
        props.transactionId,
        option.map(transactionId => (
          <Body size="medium" weight="regular">
            {formatMessage("SmartKey.transactionId", {
              transactionId,
            })}
          </Body>
        )),
        option.toNullable
      )}
    </Stack>
  );
}
