import { eq, either, option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { Either } from "fp-ts/Either";
import { Eq } from "fp-ts/Eq";
import { Monad2 } from "fp-ts/Monad";
import { Bifunctor2 } from "fp-ts/Bifunctor";
import { Alt2 } from "fp-ts/Alt";
import {
  constFalse,
  constant,
  constTrue,
  Lazy,
  identity,
  pipe,
} from "fp-ts/function";

declare module "fp-ts/HKT" {
  interface URItoKind2<E, A> {
    RemoteData: RemoteData<E, A>;
  }
}

export const URI = "RemoteData";

export type URI = typeof URI;

export interface RemoteLoading {
  readonly _tag: "Loading";
}

export interface RemoteFailure<E> {
  readonly _tag: "Failure";
  readonly failure: E;
  readonly loading: boolean;
}

export interface RemoteSuccess<A> {
  readonly _tag: "Success";
  readonly success: A;
  readonly loading: boolean;
}

export type RemoteData<E, A> =
  | RemoteLoading
  | RemoteFailure<E>
  | RemoteSuccess<A>;

/**
 * A `RemoteLoading`
 */
export const remoteLoading: RemoteData<never, never> = {
  _tag: "Loading",
};

/**
 * Constructs a `RemoteFailure`
 */
export function remoteFailure<E = never, A = never>(
  failure: E,
  loading: boolean
): RemoteData<E, A> {
  return { _tag: "Failure", failure, loading };
}

/**
 * Constructs a `RemoteSuccess`
 */
export function remoteSuccess<E = never, A = never>(
  success: A,
  loading: boolean
): RemoteData<E, A> {
  return { _tag: "Success", success, loading };
}

export function fold<E, A, B>(
  onLoading: () => B,
  onFailure: (failure: E, loading: boolean) => B,
  onSuccess: (success: A, loading: boolean) => B
): (ma: RemoteData<E, A>) => B {
  return ma => {
    switch (ma._tag) {
      case "Loading":
        return onLoading();
      case "Failure":
        return onFailure(ma.failure, ma.loading);
      case "Success":
        return onSuccess(ma.success, ma.loading);
    }
  };
}

// -------------------------------------------------------------------------------------
// non-pipeables
// -------------------------------------------------------------------------------------

const map_: Monad2<URI>["map"] = <E = never, A = never, B = never>(
  fa: RemoteData<E, A>,
  f: (a: A) => B
): RemoteData<E, B> => pipe(fa, map(f));

const mapLeft_: Bifunctor2<URI>["mapLeft"] = <E = never, A = never, B = never>(
  fa: RemoteData<E, A>,
  l: (l: E) => B
): RemoteData<B, A> => pipe(fa, mapLeft(l));

const bimap_: Bifunctor2<URI>["bimap"] = <
  E = never,
  A = never,
  B = never,
  C = never
>(
  fa: RemoteData<E, A>,
  l: (l: E) => B,
  r: (a: A) => C
): RemoteData<B, C> => pipe(fa, bimap(l, r));

const chain_: Monad2<URI>["chain"] = <L, A, B>(
  fa: RemoteData<L, A>,
  f: (a: A) => RemoteData<L, B>
): RemoteData<L, B> => pipe(fa, chain(f));

const alt_: Alt2<URI>["alt"] = <E, A>(
  fx: RemoteData<E, A>,
  fy: () => RemoteData<E, A>
): RemoteData<E, A> => pipe(fx, alt(fy));

const ap_: Monad2<URI>["ap"] = <E, A, B>(
  fab: RemoteData<E, (a: A) => B>,
  fa: RemoteData<E, A>
): RemoteData<E, B> => pipe(fab, ap(fa));

// -------------------------------------------------------------------------------------
// pipeables
// -------------------------------------------------------------------------------------

export const of: Monad2<URI>["of"] = <E = never, A = never>(
  success: A
): RemoteData<E, A> => remoteSuccess(success, false);

export const map: <A, B>(
  f: (a: A) => B
) => <E>(fa: RemoteData<E, A>) => RemoteData<E, B> = f => fa =>
  pipe(
    fa,
    fold(constant(remoteLoading), remoteFailure, (success, loading) =>
      remoteSuccess(f(success), loading)
    )
  );

export const bimap: <E, G, A, B>(
  f: (e: E) => G,
  g: (a: A) => B
) => (fa: RemoteData<E, A>) => RemoteData<G, B> = (f, g) =>
  fold(
    constant(remoteLoading),
    (failure, loading) => remoteFailure(f(failure), loading),
    (success, loading) => remoteSuccess(g(success), loading)
  );

export const mapLeft = <E, B>(f: (e: E) => B) => <A>(
  fa: RemoteData<E, A>
): RemoteData<B, A> =>
  pipe(
    fa,
    fold(
      constant(remoteLoading),
      (failure, loading) => remoteFailure<B, A>(f(failure), loading),
      remoteSuccess
    )
  );

export const ap: <E, A>(
  fa: RemoteData<E, A>
) => <B>(fab: RemoteData<E, (a: A) => B>) => RemoteData<E, B> = fa => fab =>
  pipe(
    fa,
    fold(
      () =>
        pipe(
          fab,
          fold(
            () => remoteLoading,
            failure => remoteFailure(failure, true),
            () => remoteLoading
          )
        ),
      (failure, loading) =>
        pipe(
          fab,
          fold(
            () => remoteFailure(failure, true),
            (failure, loadingb) => remoteFailure(failure, loading || loadingb),
            (_, loadingb) => remoteFailure(failure, loading || loadingb)
          )
        ),
      (success, loading) =>
        pipe(
          fab,
          fold(
            () => remoteLoading,
            (failure, loadingb) => remoteFailure(failure, loading || loadingb),
            (f, loadingb) => remoteSuccess(f(success), loading || loadingb)
          )
        )
    )
  );

export const chain: <E, A, B>(
  f: (a: A) => RemoteData<E, B>
) => (fa: RemoteData<E, A>) => RemoteData<E, B> = f =>
  fold(constant(remoteLoading), remoteFailure, f);

export const chainFirst: <E, A, B>(
  f: (a: A) => RemoteData<E, B>
) => (ma: RemoteData<E, A>) => RemoteData<E, A> = f =>
  chain(a =>
    pipe(
      f(a),
      map(() => a)
    )
  );

export const flatten: <E, A>(
  mma: RemoteData<E, RemoteData<E, A>>
) => RemoteData<E, A> = chain(identity);

export const alt: <E, A>(
  that: Lazy<RemoteData<E, A>>
) => (fa: RemoteData<E, A>) => RemoteData<E, A> = that =>
  fold(that, that, remoteSuccess);

export const remoteData: Bifunctor2<URI> & Monad2<URI> & Alt2<URI> = {
  URI,
  map: map_,
  ap: ap_,
  of,
  mapLeft: mapLeft_,
  bimap: bimap_,
  chain: chain_,
  alt: alt_,
};

export function getEq<E, A>(Eqe: Eq<E>, Eqa: Eq<A>): Eq<RemoteData<E, A>> {
  return eq.fromEquals((a, b) =>
    pipe(
      a,
      fold(
        () => b._tag === "Loading",
        fa =>
          pipe(
            b,
            fold(constFalse, fb => Eqe.equals(fa, fb), constFalse)
          ),
        sa =>
          pipe(
            b,
            fold(constFalse, constFalse, sb => Eqa.equals(sa, sb))
          )
      )
    )
  );
}

export function fromEither<E, A>(ma: Either<E, A>): RemoteData<E, A> {
  return pipe(
    ma,
    either.fold(
      error => remoteFailure(error, false),
      value => remoteSuccess(value, false)
    )
  );
}

export function toOption<E, A>(rd: RemoteData<E, A>): Option<A> {
  return pipe(
    rd,
    fold(
      () => option.none,
      () => option.none,
      (data, loading) => (loading ? option.none : option.some(data))
    )
  );
}

export function isSuccess<E, A>(
  remoteData: RemoteData<E, A>
): remoteData is RemoteSuccess<A> {
  return pipe(remoteData, fold(constFalse, constFalse, constTrue));
}

export function isLoading<E, A>(
  remoteData: RemoteData<E, A>
): remoteData is RemoteLoading {
  return pipe(remoteData, fold(constTrue, constFalse, constFalse));
}
