import { FeedbackBlock, ErrorBanner } from "design-system";
import { pipe } from "fp-ts/function";
import { IO } from "fp-ts/IO";
import { useFormatMessage } from "../../intl";
import { usePolling } from "../../useAPI";
import * as remoteData from "../../RemoteData";
import * as api from "../api";
import { StandardLoanFlowType } from "../domain";
import { option } from "fp-ts";
import { CustomerOfferPage } from "./CustomerOfferPage";
import { Option } from "fp-ts/Option";
import { ExistingClientAuthenticationMethod } from "../../globalDomain";
import { UpdateCostumerOfferProvider } from "./UpdateCustomerOfferContext";
import { CPIAdditionalQuestions } from "./api";
import { MainContent } from "../../Common/MainContent";

type Props = {
  flowType: StandardLoanFlowType;
  onBack: IO<unknown>;
  onNext: (hasRefinancingCredits: boolean) => unknown;
  onExit: (isApplicationSaved: boolean) => unknown;
  onApplicationRejected: IO<unknown>;
  onExposureExceeded: IO<unknown>;
  authenticationMethod: Option<ExistingClientAuthenticationMethod>;
  restoredAdditionalQuestions: Option<CPIAdditionalQuestions>;
  clientExists: boolean;
};

export function CustomerOffer(props: Props) {
  const formatMessage = useFormatMessage();

  const [checkCreditBureauStatus] = usePolling(api.checkCreditBureauStatus, {
    shouldPollingContinue: ({ cbResultReady }) => !cbResultReady,
    intervalMS: 3000,
  });

  const constLoader = () => (
    <FeedbackBlock
      type="loading"
      heading={formatMessage(
        "StandardLoan.CustomerOffer.waitingForCreditChecks.title"
      )}
      subheading={option.some(
        formatMessage(
          "StandardLoan.CustomerOffer.waitingForCreditChecks.message"
        )
      )}
      size="medium"
    />
  );

  return (
    <MainContent>
      {pipe(
        checkCreditBureauStatus,
        remoteData.fold(
          constLoader,
          () => <ErrorBanner>{formatMessage("GenericError")}</ErrorBanner>,
          response =>
            pipe(
              response,
              api.foldCreditBureauStatus({
                whenPending: constLoader,
                whenRejected: () => {
                  props.onApplicationRejected();
                  return null;
                },
                whenApproved: ({ hasRefinancingCredits }) => (
                  <UpdateCostumerOfferProvider
                    hasRefinancingCredits={hasRefinancingCredits}
                    onExposureExceeded={props.onExposureExceeded}
                  >
                    <CustomerOfferPage
                      flowType={props.flowType}
                      onApplicationRejected={props.onApplicationRejected}
                      hasRefinancingCredits={hasRefinancingCredits}
                      onBack={props.onBack}
                      onNext={props.onNext}
                      onExit={props.onExit}
                      authenticationMethod={props.authenticationMethod}
                      restoredAdditionalQuestions={
                        props.restoredAdditionalQuestions
                      }
                      clientExists={props.clientExists}
                    />
                  </UpdateCostumerOfferProvider>
                ),
              })
            )
        )
      )}
    </MainContent>
  );
}
