import {
  ContentRow,
  ErrorBanner,
  Loader,
  unsafePositiveInteger,
} from "design-system";
import { option, taskEither } from "fp-ts";
import { IO } from "fp-ts/IO";
import { useState } from "react";
import { useFormatMessage } from "../../intl";
import { OTPAuthorization } from "../../OTPAuthorization/OTPAuthorization";
import * as api from "../api";
import { useCommand, useQuery } from "../../useAPI";
import { OtpGenerationInput, OtpVerifyInput } from "../../OTP/domain";
import { pipe } from "fp-ts/function";
import { genericError } from "../../globalDomain";
import * as remoteData from "../../RemoteData";

type Props = {
  onSuccess: IO<unknown>;
  onFailure: IO<unknown>;
};

export function AuthorizeWithOTP(props: Props) {
  const formatMessage = useFormatMessage();
  const [authStarted, setAuthStarted] = useState(false);
  // const [, setLFStatus] = useState<Option<api.LFStatus>>(option.none);

  const [phoneNumber] = useQuery(api.phoneNumber);

  const generateOtp = useCommand(api.generateOtp);
  const verifyOTPCommand = useCommand(api.otpVerify);

  const onOtpFailure = () => {
    setAuthStarted(false);
    props.onFailure();
  };

  const generateOtpCommand = (input: OtpGenerationInput) =>
    pipe(
      generateOtp(input),
      taskEither.fold(
        () => {
          props.onFailure();
          return taskEither.left(genericError);
        },
        response =>
          taskEither.right({ ...response, transactionId: option.none })
      )
    );

  return pipe(
    phoneNumber,
    remoteData.map(result => result.phoneNumber),
    remoteData.fold(
      () => <Loader />,
      () => (
        <ContentRow type="lateral-margins">
          <ErrorBanner children={formatMessage("GenericError")} />
        </ContentRow>
      ),
      phoneNumber => (
        <OTPAuthorization
          phoneNumber={phoneNumber}
          onSuccess={taskEither.fromIO(props.onSuccess)}
          onFailure={onOtpFailure}
          requestNotice={option.none}
          otpRequestButtonLabel={formatMessage(
            "Identification.otp.submitValue"
          )}
          otpSubmitButtonLabel={formatMessage("Identification.otp.signViaOTP")}
          otpTitleLabel={formatMessage(
            "Identification.otp.authorizeWithSMS.title"
          )}
          processStarted={authStarted}
          onProcessStart={taskEither.fromIO(() => setAuthStarted(true))}
          onGenerateOTP={generateOtpCommand}
          onVerifyOTP={(data: OtpVerifyInput) =>
            verifyOTPCommand({ otp: data.otp })
          }
          length={unsafePositiveInteger(6)}
          allowResendOTP
        />
      )
    )
  );
}
