import { array, boolean, option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { Reader } from "fp-ts/Reader";
import * as api from "../api";
import { CPIAdditionalQuestions } from "./InsuranceChoose";

interface LoadingState {
  type: "Loading";
}

interface GenericErrorState {
  type: "GenericError";
}

interface NotAllowedErrorState {
  type: "NotAllowedError";
}

interface InitialState {
  type: "InitialState";
  cpiPackageList: api.CPIPackageList;
  currentInsuranceType: api.CreditProtectionInsuranceType;
  currentSolvencyAnswers: Option<api.InsuranceSolvencyAnswers>;
  additionalAnswers: Option<CPIAdditionalQuestions>;
  didAnswerSolvencyQuestions: boolean;
}

export function initialState(): LoadingState {
  return {
    type: "Loading",
  };
}

interface InsuranceChoiceState {
  type: "InsuranceChoice";
  currentInsuranceType: api.CreditProtectionInsuranceType;
  currentSolvencyAnswers: Option<api.InsuranceSolvencyAnswers>;
  cpiPackageList: api.CPIPackageList;
  additionalAnswers: Option<CPIAdditionalQuestions>;
  didAnswerSolvencyQuestions: boolean;
}

interface InsuranceSolvencyState {
  type: "InsuranceSolvency";
  previousInsuranceType: api.CreditProtectionInsuranceType;
  previousSolvencyAnswers: Option<api.InsuranceSolvencyAnswers>;
  chosenInsuranceType: api.CreditProtectionInsuranceType;
  cpiPackageList: api.CPIPackageList;
  additionalAnswers: Option<CPIAdditionalQuestions>;
  didAnswerSolvencyQuestions: boolean;
}

type State =
  | LoadingState
  | NotAllowedErrorState
  | GenericErrorState
  | InitialState
  | InsuranceChoiceState
  | InsuranceSolvencyState;

export function foldState<T>(
  matches: {
    [k in State["type"]]: Reader<Extract<State, { type: k }>, T>;
  }
): Reader<State, T> {
  return state => matches[state.type](state as any);
}

interface InitAction {
  type: "Init";
  insuranceType: api.CreditProtectionInsuranceType;
  solvencyAnswers: Option<api.InsuranceSolvencyAnswers>;
  cpiPackageList: api.CPIPackageList;
}

interface ErrorAction {
  type: "Error";
  error: api.InsuranceError;
}

interface ListInsurancesAction {
  type: "ListInsurances";
  cpiPackageList: api.CPIPackageList;
}

interface ChooseInsuranceAction {
  type: "ChooseInsurance";
  insuranceType: api.CreditProtectionInsuranceType;
  additionalAnswers: Option<CPIAdditionalQuestions>;
}

interface ReviewSolvencyQuestionsAction {
  type: "ReviewSolvencyQuestions";
}

interface AnswerSolvencyQuestionsAction {
  type: "AnswerSolvencyQuetions";
  solvencyAnswers: api.InsuranceSolvencyAnswers;
  cpiPackageList: api.CPIPackageList;
}

interface UpdateCPIPackageList {
  type: "UpdateCPIPackageList";
  cpiPackageList: api.CPIPackageList;
}

interface Cancel {
  type: "Cancel";
  additionalAnswers: Option<CPIAdditionalQuestions>;
}

type Action =
  | InitAction
  | ErrorAction
  | ListInsurancesAction
  | ChooseInsuranceAction
  | UpdateCPIPackageList
  | ReviewSolvencyQuestionsAction
  | AnswerSolvencyQuestionsAction
  | Cancel;

export function reducer(state: State, action: Action): State {
  if (action.type === "Error") {
    if (action.error.id === "AgeLimitError") {
      return {
        type: "NotAllowedError",
      };
    }
    return {
      type: "GenericError",
    };
  }

  switch (state.type) {
    case "Loading":
      switch (action.type) {
        case "Init":
          return {
            type: "InitialState",
            currentInsuranceType: action.insuranceType,
            currentSolvencyAnswers: action.solvencyAnswers,
            cpiPackageList: action.cpiPackageList,
            additionalAnswers: option.none,
            didAnswerSolvencyQuestions: false,
          };
        case "UpdateCPIPackageList":
        case "ListInsurances":
        case "ChooseInsurance":
        case "ReviewSolvencyQuestions":
        case "AnswerSolvencyQuetions":
        case "Cancel":
          return state;
      }
    case "InitialState":
      switch (action.type) {
        case "ListInsurances":
          return {
            type: "InsuranceChoice",
            currentInsuranceType: state.currentInsuranceType,
            currentSolvencyAnswers: state.currentSolvencyAnswers,
            cpiPackageList: action.cpiPackageList,
            additionalAnswers: state.additionalAnswers,
            didAnswerSolvencyQuestions: false,
          };
        case "UpdateCPIPackageList":
          return {
            ...state,
            cpiPackageList: action.cpiPackageList,
          };
        case "Init":
        case "ChooseInsurance":
        case "ReviewSolvencyQuestions":
        case "AnswerSolvencyQuetions":
        case "Cancel":
          return state;
      }
    case "InsuranceChoice":
      switch (action.type) {
        case "ChooseInsurance":
          if (action.insuranceType === "None") {
            return {
              type: "InitialState",
              currentInsuranceType: "None",
              currentSolvencyAnswers: state.currentSolvencyAnswers,
              cpiPackageList: state.cpiPackageList,
              additionalAnswers: action.additionalAnswers,
              didAnswerSolvencyQuestions: false,
            };
          } else {
            return pipe(
              state.currentSolvencyAnswers,
              option.fold<api.InsuranceSolvencyAnswers, State>(
                () => ({
                  type: "InsuranceSolvency",
                  previousInsuranceType: state.currentInsuranceType,
                  previousSolvencyAnswers: state.currentSolvencyAnswers,
                  chosenInsuranceType: action.insuranceType,
                  cpiPackageList: state.cpiPackageList,
                  additionalAnswers: action.additionalAnswers,
                  didAnswerSolvencyQuestions: false,
                }),
                answers => ({
                  type: "InitialState",
                  currentInsuranceType: action.insuranceType,
                  currentSolvencyAnswers: option.some(answers),
                  cpiPackageList: state.cpiPackageList,
                  additionalAnswers: action.additionalAnswers,
                  didAnswerSolvencyQuestions: false,
                })
              )
            );
          }
        case "ReviewSolvencyQuestions":
          return {
            type: "InsuranceSolvency",
            chosenInsuranceType: state.currentInsuranceType,
            previousInsuranceType: state.currentInsuranceType,
            cpiPackageList: state.cpiPackageList,
            previousSolvencyAnswers: state.currentSolvencyAnswers,
            additionalAnswers: state.additionalAnswers,
            didAnswerSolvencyQuestions: false,
          };
        case "Cancel":
          return {
            type: "InitialState",
            currentInsuranceType: state.cpiPackageList.selectedInsurance,
            currentSolvencyAnswers: state.currentSolvencyAnswers,
            cpiPackageList: state.cpiPackageList,
            additionalAnswers: pipe(
              state.additionalAnswers,
              option.isSome,
              boolean.fold(
                () => action.additionalAnswers,
                () => state.additionalAnswers
              )
            ),
            didAnswerSolvencyQuestions: false,
          };
        case "UpdateCPIPackageList":
          return {
            ...state,
            cpiPackageList: action.cpiPackageList,
          };
        case "AnswerSolvencyQuetions":
        case "Init":
        case "ListInsurances":
          return state;
      }
    case "InsuranceSolvency":
      switch (action.type) {
        case "AnswerSolvencyQuetions":
          return {
            type: pipe(
              action.cpiPackageList.options,
              array.findFirst(
                option =>
                  option.type === state.chosenInsuranceType &&
                  option.eligible &&
                  (state.chosenInsuranceType === "Full" ||
                    (state.chosenInsuranceType === "Standard" &&
                      action.cpiPackageList.options.some(
                        cpi => cpi.type === "Full" && !cpi.eligible
                      )) ||
                    (state.chosenInsuranceType === "Basic" &&
                      !action.cpiPackageList.options.some(
                        cpi =>
                          (cpi.type === "Full" || cpi.type === "Standard") &&
                          cpi.eligible
                      )))
              ),
              option.fold(
                () => "InsuranceChoice",
                () => "InitialState"
              )
            ),
            currentSolvencyAnswers: option.some(action.solvencyAnswers),
            cpiPackageList: action.cpiPackageList,
            currentInsuranceType: pipe(
              action.cpiPackageList.options,
              array.findFirst(option => option.eligible),
              option.fold(
                () => "None",
                () =>
                  pipe(
                    action.cpiPackageList.options,
                    array.findFirst(
                      ({ type }) => type === state.chosenInsuranceType
                    ),
                    option.fold(
                      () => state.previousInsuranceType,
                      option =>
                        pipe(
                          option.eligible,
                          boolean.fold(
                            () => "None",
                            () => state.chosenInsuranceType
                          )
                        )
                    )
                  )
              )
            ),
            additionalAnswers: state.additionalAnswers,
            didAnswerSolvencyQuestions: true,
          };
        case "Cancel":
          return {
            type: "InsuranceChoice",
            currentInsuranceType: state.previousInsuranceType,
            currentSolvencyAnswers: state.previousSolvencyAnswers,
            cpiPackageList: state.cpiPackageList,
            additionalAnswers: state.additionalAnswers,
            didAnswerSolvencyQuestions: false,
          };
        case "UpdateCPIPackageList":
          return {
            ...state,
            cpiPackageList: action.cpiPackageList,
          };
        case "Init":
        case "ListInsurances":
        case "ChooseInsurance":
        case "ReviewSolvencyQuestions":
          return state;
      }
    case "GenericError":
    case "NotAllowedError":
      return state;
  }
}
