import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Space,
  Stack,
  Loader,
  ContentRow,
  useIsMobileLayout,
  ErrorBanner,
  unsafePositiveInteger,
  PageHeading,
} from "design-system";
import { constant, constNull, constVoid, pipe } from "fp-ts/function";
import { IO } from "fp-ts/IO";
import { useFormatMessage } from "../../intl";
import { BackButton } from "../../Common/BackButton/BackButton";
import { CustomerOfferConfigurator } from "./CustomerOfferConfigurator";
import { LiabilityFormModel, StandardLoanFlowType } from "../domain";
import { RefinancingCreditsCheck } from "../Refinancing/RefinancingCreditsCheck";
import { boolean, option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { ExistingClientAuthenticationMethod } from "../../globalDomain";
import {
  useUpdateCustomerOfferContext,
  foldAPIStatus,
  OfferResponse,
} from "./UpdateCustomerOfferContext";
import { SelectedLoansInformation } from "../Refinancing/RefinancingAccountSelection";
import { CPIAdditionalQuestions } from "./api";
import { useTenantCurrency } from "../../Common/useTenantCurrency";
import { isTL } from "../../ClientProfile/domain";
import { palette } from "design-system/lib/styleConstants";

type Props = {
  flowType: StandardLoanFlowType;
  onBack: IO<unknown>;
  onNext: (hasRefinancingCredits: boolean) => unknown;
  onExit: (isApplicationSaved: boolean) => unknown;
  onApplicationRejected: IO<unknown>;
  hasRefinancingCredits: boolean;
  authenticationMethod: Option<ExistingClientAuthenticationMethod>;
  restoredAdditionalQuestions: Option<CPIAdditionalQuestions>;
  clientExists: boolean;
};

export function CustomerOfferPage(props: Props) {
  const [
    selectedRefinancingCredits,
    setSelectedRefinancingCredits,
  ] = useState<SelectedLoansInformation>({
    internal: {
      count: 0,
      amount: 0,
    },
    external: {
      count: 0,
      amount: 0,
    },
  });
  const currency = useTenantCurrency();

  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();

  const {
    initialLoanOfferState,
    loanOfferState,
    refinancingLoanState,
    preapprovedLimitState,
    updatedOfferStatus,
    handleUpdateCustomerOffer,
  } = useUpdateCustomerOfferContext();

  const [showInitialOffer, setShowInitialOffer] = useState(true);
  useEffect(
    () =>
      pipe(
        loanOfferState,
        foldAPIStatus({
          whenError: () => setShowInitialOffer(false),
          whenLoading: constVoid,
          whenSuccess: () => setShowInitialOffer(false),
        })
      ),
    [loanOfferState]
  );

  const getLoanOffer = () =>
    showInitialOffer ? initialLoanOfferState : loanOfferState;

  useEffect(() => {
    pipe(
      getLoanOffer(),
      foldAPIStatus({
        whenError: constVoid,
        whenLoading: constVoid,
        whenSuccess: data =>
          handleUpdateCustomerOffer({
            ...data.genericLoan,
            installmentDay: pipe(
              data.genericLoan.installmentDay,
              option.getOrElse(() => unsafePositiveInteger(1)) // @TODO: should never happens
            ),
          }),
      })
    );
  }, [selectedRefinancingCredits]);

  const preapprovedLimit =
    preapprovedLimitState.status === "success" &&
    isTL(preapprovedLimitState.data)
      ? pipe(
          preapprovedLimitState.data.preapprovedLimits.TL,
          option.fold(constant(0), TL => TL.maximumpreapprovedlimit)
        )
      : 0;

  const preapprovedLimitCurrency =
    preapprovedLimitState.status === "success" &&
    isTL(preapprovedLimitState.data)
      ? pipe(
          preapprovedLimitState.data.preapprovedLimits.TL,
          option.fold(constant(currency), TL => TL.currency)
        )
      : currency;

  const { external, internal, allInternalLoanSelected } = useMemo(
    () =>
      pipe(
        refinancingLoanState,
        foldAPIStatus({
          whenError: () => ({
            external: [],
            internal: [],
            allInternalLoanSelected: false,
          }),
          whenLoading: () => ({
            external: [],
            internal: [],
            allInternalLoanSelected: false,
          }),
          whenSuccess: liabilities => {
            const internal = pipe(
              liabilities.internalLoans,
              option.map(internalLoans =>
                internalLoans.liabilitiesList.map(internalLoan => ({
                  ...internalLoan,
                  startDate: internalLoan.startDate,
                  selected: internalLoan.selectedForRefinance,
                }))
              ),
              option.getOrElse<LiabilityFormModel[]>(() => [])
            );

            const external = pipe(
              liabilities.externalLoans,
              option.map(externalLoans =>
                externalLoans.liabilitiesList.map(externalLoan => ({
                  ...externalLoan,
                  startDate: externalLoan.startDate,
                  selected: externalLoan.selectedForRefinance,
                }))
              ),
              option.getOrElse<LiabilityFormModel[]>(() => [])
            );

            const allInternalLoanSelected =
              internal.length > 0 &&
              internal.length === selectedRefinancingCredits.internal.count;

            return {
              internal,
              external,
              allInternalLoanSelected,
            };
          },
        })
      ),
    [refinancingLoanState, selectedRefinancingCredits]
  );

  const renderCustomerOfferConfigurations = (data: OfferResponse) => (
    <CustomerOfferConfigurator
      flowType={props.flowType}
      loanOffer={data.genericLoan}
      allInternalLoanSelected={allInternalLoanSelected}
      onNext={() => props.onNext(props.hasRefinancingCredits)}
      hasRefinancingCredits={props.hasRefinancingCredits}
      selectedRefinancingCredits={selectedRefinancingCredits}
      onExit={props.onExit}
      authenticationMethod={props.authenticationMethod}
      onUpdateCustomerOffer={constVoid}
      restoredAdditionalQuestions={props.restoredAdditionalQuestions}
      cpiPackageList={data.cpiPackageList}
    />
  );

  const getValueFromOptionNumber = (value: Option<number>) =>
    pipe(
      value,
      option.getOrElse(() => 0)
    );

  const [refinancingInLoading, setRefinancingInLoading] = useState(false);
  const [offerStatusInLoading, setOfferStatusInLoading] = useState(false);
  const [lastTopUpActive, setLastTopUpActive] = useState(false);

  const renderInitialOffer = () =>
    pipe(
      initialLoanOfferState,
      foldAPIStatus({
        whenError: () => {
          if (offerStatusInLoading) {
            setOfferStatusInLoading(false);
          }
          return null;
        },
        whenLoading: () => {
          if (!offerStatusInLoading) {
            setOfferStatusInLoading(true);
          }
          return null;
        },
        whenSuccess: data => {
          if (offerStatusInLoading) {
            setOfferStatusInLoading(false);
          }
          return renderCustomerOfferConfigurations(data);
        },
      })
    );

  const renderUpdatedOffer = () =>
    pipe(
      loanOfferState,
      foldAPIStatus({
        whenError: () => {
          if (offerStatusInLoading) {
            setOfferStatusInLoading(false);
          }
          return null;
        },
        whenLoading: oldData =>
          pipe(
            oldData,
            option.fold(() => {
              if (!offerStatusInLoading) {
                setOfferStatusInLoading(true);
              }
              return null;
            }, renderCustomerOfferConfigurations)
          ),
        whenSuccess: data => {
          if (offerStatusInLoading) {
            setOfferStatusInLoading(false);
          }
          return renderCustomerOfferConfigurations(data);
        },
      })
    );

  return (
    <Box column grow shrink>
      <PageHeading
        title={formatMessage("StandardLoan.CustomerOffer.title")}
        hideOnMobile
      />

      {loanOfferState.status === "error" && (
        <ErrorBanner>{formatMessage("GenericError")}</ErrorBanner>
      )}

      <Stack column grow shrink units={10}>
        {pipe(
          props.hasRefinancingCredits,
          boolean.fold(constNull, () =>
            pipe(
              refinancingLoanState,
              foldAPIStatus({
                whenError: () => {
                  if (refinancingInLoading) setRefinancingInLoading(false);
                  return (
                    <ErrorBanner>{formatMessage("GenericError")}</ErrorBanner>
                  );
                },
                whenLoading: () => {
                  if (!refinancingInLoading) setRefinancingInLoading(true);
                  return null;
                },
                whenSuccess: data => {
                  if (refinancingInLoading) setRefinancingInLoading(false);
                  return (
                    <RefinancingCreditsCheck
                      onLoanSelection={setSelectedRefinancingCredits}
                      clientExists={props.clientExists}
                      preapprovedLimitState={preapprovedLimitState}
                      liabilities={data}
                      external={external}
                      internal={internal}
                      preapprovedLimit={preapprovedLimit}
                      preapprovedLimitCurrency={preapprovedLimitCurrency}
                      eligibleForPreapproved={pipe(
                        getLoanOffer(),
                        foldAPIStatus({
                          whenError: () => 0,
                          whenLoading: oldData =>
                            pipe(
                              oldData,
                              option.fold(
                                () => 0,
                                data =>
                                  pipe(
                                    data.genericLoan.eligibleForPreapproved,
                                    option.fold(
                                      () => 0,
                                      eligibleForPreapproved =>
                                        eligibleForPreapproved
                                    )
                                  )
                              )
                            ),
                          whenSuccess: data =>
                            pipe(
                              data.genericLoan.eligibleForPreapproved,
                              option.fold(
                                () => 0,
                                eligibleForPreapproved => eligibleForPreapproved
                              )
                            ),
                        })
                      )}
                      isTopUpActive={pipe(
                        getLoanOffer(),
                        foldAPIStatus({
                          whenError: () => false,
                          whenLoading: () => lastTopUpActive,
                          whenSuccess: data => {
                            const productType = pipe(
                              data.genericLoan.productType,
                              option.fold(
                                () => "",
                                productType => productType
                              )
                            );

                            const isTopUpActive =
                              option.isSome(props.authenticationMethod) &&
                              productType === "TL";
                            if (lastTopUpActive !== isTopUpActive) {
                              setLastTopUpActive(isTopUpActive);
                            }

                            return isTopUpActive;
                          },
                        })
                      )}
                      refinancingLimitExceeded={
                        updatedOfferStatus ===
                        "REFINANCED_CREDITS_LIMIT_EXCEEDED"
                      }
                      maxLoanAmount={pipe(
                        getLoanOffer(),
                        foldAPIStatus({
                          whenError: () => 0,
                          whenLoading: oldData =>
                            pipe(
                              oldData,
                              option.fold(
                                () => 0,
                                data => {
                                  const maxAmount = getValueFromOptionNumber(
                                    data.genericLoan.maxAllowedAmount
                                  );
                                  const bankingFee = getValueFromOptionNumber(
                                    data.genericLoan.bankingFee
                                  );
                                  const result = maxAmount - bankingFee;

                                  return result < 0 ? 0 : result;
                                }
                              )
                            ),
                          whenSuccess: data => {
                            const maxAmount = getValueFromOptionNumber(
                              data.genericLoan.maxAllowedAmount
                            );
                            const bankingFee = getValueFromOptionNumber(
                              data.genericLoan.bankingFee
                            );
                            const result = maxAmount - bankingFee;

                            return result < 0 ? 0 : result;
                          },
                        })
                      )}
                    />
                  );
                },
              })
            )
          )
        )}
        {pipe(
          updatedOfferStatus === "OK",
          boolean.fold(constNull, () => (
            <Box style={{ backgroundColor: palette.white }}>
              <ContentRow type="lateral-margins">
                <Box column grow shrink>
                  <Space units={10} />
                  {showInitialOffer
                    ? renderInitialOffer()
                    : renderUpdatedOffer()}
                </Box>
              </ContentRow>
            </Box>
          ))
        )}
        {(refinancingInLoading || offerStatusInLoading) && (
          <Box hAlignContent="center">
            <Loader />
          </Box>
        )}
        <ContentRow type="lateral-margins">
          <Box grow column={isMobileLayout}>
            <BackButton action={props.onBack} />
          </Box>
        </ContentRow>
      </Stack>
    </Box>
  );
}
