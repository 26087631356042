import { useCommand, useQuery } from "../../../useAPI";
import * as api from "../api";
import { Option } from "fp-ts/Option";
import { Box, ErrorBanner, Loader } from "design-system";
import { constVoid, pipe } from "fp-ts/function";
import { useFormatMessage } from "../../../intl";
import { MoneyAmount } from "../../../globalDomain";
import { useEffect, useReducer } from "react";
import { foldState, initialState, reducer } from "./CPIDialogState";
import { CPIBanner } from "./CPIBanner";
import { InsuranceChoose } from "./InsuranceChoose";
import { array, option, taskEither } from "fp-ts";
import { InsuranceSolvency } from "./InsuranceSolvency";
import { sequenceS } from "fp-ts/Apply";
import * as remoteData from "../../../RemoteData";
import { setSelectedInsurance as setSelectedInsuranceCommand } from "../../api";
import { useUpdateEffect } from "react-use";

type Props = {
  restoredAdditionalQuestions: Option<api.CPIAdditionalQuestions>;
  currentOfferInstallment: MoneyAmount;
  onCpiChange: () => void;
  onCpiInitialState: () => void;
  cpiPackageList: api.CPIPackageList;
  onNoCPIWanted: () => unknown;
  noSelection: boolean;
};

export function CPIDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const [state, dispatch] = useReducer(reducer, initialState());
  const [insuranceType] = useQuery(api.getSelectedInsurance);
  const [solvencyAnswers] = useQuery(api.getInsuranceSolvencyAnswers);
  const setSelectedInsurance = useCommand(setSelectedInsuranceCommand);

  useEffect(() => {
    if (state.type === "Loading") {
      pipe(
        {
          insuranceType,
          solvencyAnswers,
        },
        sequenceS(remoteData.remoteData),
        remoteData.fold(
          constVoid,
          error => {
            return dispatch({ type: "Error", error });
          },
          ({ insuranceType, solvencyAnswers }) => {
            dispatch({
              type: "Init",
              insuranceType: insuranceType.selectedInsurance,
              solvencyAnswers: pipe(
                solvencyAnswers,
                option.chain(({ cpiAnswers }) => cpiAnswers)
              ),
              cpiPackageList: props.cpiPackageList,
            });
          }
        )
      );
    }
  }, [state, props.cpiPackageList, insuranceType, solvencyAnswers]);

  useUpdateEffect(() => {
    if (props.noSelection && state.type === "InitialState") {
      dispatch({
        type: "ListInsurances",
        cpiPackageList: state.cpiPackageList,
      });
    }
  }, [props.noSelection]);

  useUpdateEffect(() => {
    if (state.type === "InitialState") {
      props.onCpiInitialState();
    }
  }, [state]);

  useEffect(() => {
    dispatch({
      type: "UpdateCPIPackageList",
      cpiPackageList: props.cpiPackageList,
    });
  }, [props.cpiPackageList]);

  return pipe(
    state,
    foldState({
      Loading: () => (
        <Box column hAlignContent="center">
          <Loader />
        </Box>
      ),
      NotAllowedError: () => <></>,
      GenericError: () => (
        <Box column>
          <ErrorBanner>{formatMessage("GenericError")}</ErrorBanner>
        </Box>
      ),
      InitialState: state => (
        <CPIBanner
          currentInsuranceType={state.currentInsuranceType}
          cpiPackageList={state.cpiPackageList}
          onClick={() =>
            dispatch({
              type: "ListInsurances",
              cpiPackageList: state.cpiPackageList,
            })
          }
        />
      ),
      InsuranceChoice: state => (
        <InsuranceChoose
          noSelection={props.noSelection}
          currentInsuranceType={state.currentInsuranceType}
          cpiPackageList={state.cpiPackageList}
          currentOfferInstallment={props.currentOfferInstallment}
          didAnswerSolvencyQuestions={option.isSome(
            state.currentSolvencyAnswers
          )}
          solvencyAnswered={state.didAnswerSolvencyQuestions}
          onBack={additionalAnswers => {
            dispatch({ type: "Cancel", additionalAnswers });
            props.onCpiChange();
          }}
          onDismiss={additionalAnswers =>
            taskEither.fromIO(() => {
              dispatch({ type: "Cancel", additionalAnswers });
              props.onCpiChange();
            })
          }
          onNoCPIWanted={props.onNoCPIWanted}
          onInsuranceChosen={(insuranceType, additionalAnswers) =>
            pipe(
              state.currentSolvencyAnswers,
              option.fold(
                () => taskEither.fromIO(constVoid),
                () =>
                  pipe(
                    setSelectedInsurance({
                      selectedInsurance: insuranceType,
                    }),
                    taskEither.chain(({ success }) => {
                      if (success) {
                        state.cpiPackageList.selectedInsurance = insuranceType;
                        return taskEither.fromIO(() => props.onCpiChange());
                      }

                      return taskEither.left(null);
                    })
                  )
              ),
              taskEither.chain(() =>
                taskEither.fromIO(() => {
                  dispatch({
                    type: "ChooseInsurance",
                    insuranceType,
                    additionalAnswers,
                  });
                })
              )
            )
          }
          onReviewSolvencyQuestions={() =>
            dispatch({ type: "ReviewSolvencyQuestions" })
          }
          additionalAnswers={pipe(
            state.additionalAnswers,
            option.fold(
              () =>
                pipe(
                  props.restoredAdditionalQuestions,
                  option.fold(
                    () => state.additionalAnswers,
                    restoredData =>
                      option.some({
                        ...restoredData,
                        experienceWithCPI: option.some(
                          restoredData.experienceWithCPI
                        ),
                        knowledge: option.some(restoredData.knowledge),
                        options: false,
                      })
                  )
                ),
              questionsInState => option.some(questionsInState)
            )
          )}
        />
      ),
      InsuranceSolvency: state => (
        <InsuranceSolvency
          solvencyData={state.previousSolvencyAnswers}
          cpiPackageList={state.cpiPackageList}
          currentInsuranceType={state.chosenInsuranceType}
          onSolvencyAnswered={({ answers, cpiPackageList }) => {
            const skipCpi = pipe(
              cpiPackageList.options,
              array.findFirst(
                option =>
                  option.type === state.chosenInsuranceType &&
                  (!option.eligible ||
                    (option.eligible &&
                      (state.chosenInsuranceType === "Full" ||
                        (state.chosenInsuranceType === "Standard" &&
                          cpiPackageList.options.some(
                            cpi => cpi.type === "Full" && !cpi.eligible
                          )) ||
                        (state.chosenInsuranceType === "Basic" &&
                          !cpiPackageList.options.some(
                            cpi =>
                              (cpi.type === "Full" ||
                                cpi.type === "Standard") &&
                              cpi.eligible
                          )))))
              )
            );
            return pipe(
              skipCpi,
              option.fold(
                () => {
                  dispatch({
                    type: "AnswerSolvencyQuetions",
                    cpiPackageList,
                    solvencyAnswers: answers,
                  });
                  props.onCpiChange();
                  return taskEither.fromIO(constVoid);
                },
                () => {
                  const setSelCall = setSelectedInsurance({
                    selectedInsurance: cpiPackageList.options.some(
                      cpi =>
                        cpi.type === state.chosenInsuranceType && cpi.eligible
                    )
                      ? state.chosenInsuranceType
                      : "None",
                  });

                  pipe(
                    setSelCall,
                    taskEither.chain(({ success }) => {
                      if (!success) {
                        return taskEither.left(null);
                      } else {
                        if (
                          !cpiPackageList.options.some(
                            cpi =>
                              cpi.type === state.chosenInsuranceType &&
                              cpi.eligible
                          )
                        ) {
                          cpiPackageList.selectedInsurance = "None";
                        } else {
                          cpiPackageList.selectedInsurance =
                            state.chosenInsuranceType;
                        }

                        return taskEither.fromIO(() => props.onCpiChange());
                      }
                    }),
                    taskEither.chain(() => {
                      dispatch({
                        type: "AnswerSolvencyQuetions",
                        cpiPackageList,
                        solvencyAnswers: answers,
                      });
                      return taskEither.fromIO(() => {});
                    })
                  )();
                  return pipe(taskEither.fromIO(() => {}));
                }
              )
            );
          }}
          onBack={() => {
            dispatch({ type: "Cancel", additionalAnswers: option.none });
          }}
        />
      ),
    })
  );
}
