import { AuthenticationContextProvider } from "./AuthenticationContext";
import { option, taskEither } from "fp-ts";
import { AppProvider } from "./AppProvider";
import { EnvBadge } from "./Common/EnvBadge/EnvBadge";

type Props = React.ComponentProps<typeof AppProvider>;

export function RemoteNoAuthAppProvider(props: Props) {
  return (
    <AppProvider {...props}>
      <AuthenticationContextProvider
        authInfo={option.none}
        potentialClientToken={taskEither.of(option.none)}
      >
        {props.children}
        <EnvBadge />
      </AuthenticationContextProvider>
    </AppProvider>
  );
}
