import {
  Banner,
  Body,
  Box,
  Button,
  CheckboxField,
  Dialog,
  FormRow,
  FormSection,
  Space,
  Stack,
  UnorderedList,
  unsafeLocalizedString,
  useForm,
  validators,
} from "design-system";
import { Option } from "fp-ts/Option";
import { array, boolean, option, taskEither } from "fp-ts";
import { useFormatMessage } from "../../../intl";
import { YesNoRadioGroupField } from "../../../Common/YesNoRadioGroup/YesNoRadioGroupField";
import { useAppContext } from "../../../useAppContext";
import { foldTenant } from "../../../globalDomain";
import { useValidators } from "../../../Common/useValidators";
import { useMemo, useState } from "react";
import { pipe } from "fp-ts/function";
import { IO } from "fp-ts/IO";
import { useCommand } from "../../../useAPI";
import * as api from "../api";
import { TaskEither } from "fp-ts/TaskEither";

interface ValidatedSolvencyFormData {
  agreement: boolean;
  firstStepQuestions: boolean;
  secondStepQuestions: boolean;
}

interface FormState {
  answers: api.InsuranceSolvencyAnswers;
  cpiPackageList: api.CPIPackageList;
}

interface Props {
  currentInsuranceType: api.CreditProtectionInsuranceType;
  solvencyData: Option<api.InsuranceSolvencyAnswers>;
  cpiPackageList: api.CPIPackageList;
  onBack: IO<unknown>;
  onSolvencyAnswered: (state: FormState) => TaskEither<unknown, unknown>;
}

const areSameAnswers = (
  ans1: api.InsuranceSolvencyAnswers,
  ans2: api.InsuranceSolvencyAnswers
): boolean => api.eqInsuranceSolvencyAnswers.equals(ans1, ans2);

export function InsuranceSolvency(props: Props) {
  const formatMessage = useFormatMessage();

  const [currentFormState, setCurrentFormState] = useState<Option<FormState>>(
    option.none
  );

  const saveInsuranceSolvencyAnswers = useCommand(
    api.setInsuranceSolvencyAnswers
  );

  const cpiPackageList = pipe(
    currentFormState,
    option.fold(
      () => props.cpiPackageList,
      ({ cpiPackageList }) => cpiPackageList
    )
  );

  const isPackageEligible_ = (
    packageType: api.CreditProtectionInsuranceType,
    cpiPackageList: api.CPIPackageList
  ) =>
    pipe(
      cpiPackageList.options,
      array.findFirst(({ type }) => type === packageType),
      option.exists(({ eligible }) => eligible)
    );

  const calcEligib = (cpiPackageList: api.CPIPackageList) => {
    const isBasicPackageEligible = isPackageEligible_("Basic", cpiPackageList);
    const isStandardPackageEligible = isPackageEligible_(
      "Standard",
      cpiPackageList
    );
    const isFullPackageEligible = isPackageEligible_("Full", cpiPackageList);
    const noPackagesEligible =
      !isBasicPackageEligible &&
      !isStandardPackageEligible &&
      !isFullPackageEligible;
    const currentPackageEligible = isPackageEligible_(
      props.currentInsuranceType,
      cpiPackageList
    );
    const questionnaireResultError =
      noPackagesEligible ||
      (props.currentInsuranceType !== "None" && !currentPackageEligible);
    const questionnaireResultInfo =
      currentPackageEligible &&
      ((props.currentInsuranceType === "Basic" &&
        (isStandardPackageEligible || isFullPackageEligible)) ||
        (props.currentInsuranceType === "Standard" && isFullPackageEligible));
    const resultErrorMessage = noPackagesEligible
      ? formatMessage(
          "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.PackageEligibility.noOne"
        )
      : formatMessage(
          "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.PackageEligibility.noCurrent"
        );
    const resultInfoMessage = formatMessage(
      "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.PackageEligibility.higher"
    );
    const questionnaireResult =
      questionnaireResultInfo || questionnaireResultError;
    return {
      resultErrorMessage,
      resultInfoMessage,
      questionnaireResult,
      questionnaireResultInfo,
    };
  };

  const {
    resultErrorMessage,
    resultInfoMessage,
    questionnaireResult,
    questionnaireResultInfo,
  } = useMemo(() => {
    return calcEligib(cpiPackageList);
  }, [cpiPackageList]);

  const {
    apiParameters: { tenant },
  } = useAppContext();

  const { defined } = useValidators();

  const saveAnswers = (answers: api.InsuranceSolvencyAnswers) =>
    pipe(
      saveInsuranceSolvencyAnswers(answers),
      taskEither.chain(({ cpiPackageList }) =>
        taskEither.fromIO(() => {
          const isBasicPackageEligible = isPackageEligible_(
            "Basic",
            cpiPackageList
          );
          const isStandardPackageEligible = isPackageEligible_(
            "Standard",
            cpiPackageList
          );
          const isFullPackageEligible = isPackageEligible_(
            "Full",
            cpiPackageList
          );
          const areAllPackagesEligible_ =
            isBasicPackageEligible &&
            isStandardPackageEligible &&
            isFullPackageEligible;

          const { questionnaireResult } = calcEligib(cpiPackageList);

          if (
            !questionnaireResult ||
            (props.currentInsuranceType === "Full" &&
              areAllPackagesEligible_) ||
            (props.currentInsuranceType === "Standard" &&
              isStandardPackageEligible &&
              !isFullPackageEligible) ||
            (props.currentInsuranceType === "Basic" &&
              isBasicPackageEligible &&
              !isStandardPackageEligible &&
              !isFullPackageEligible)
          ) {
            return props.onSolvencyAnswered({ answers, cpiPackageList });
          }
          return setCurrentFormState(option.some({ answers, cpiPackageList }));
        })
      )
    );

  const { fieldProps, handleSubmit } = useForm(
    {
      initialValues: pipe(
        props.solvencyData,
        option.fold(
          () => ({
            agreement: true,
            firstStepQuestions: option.none as Option<boolean>,
            secondStepQuestions: option.none as Option<boolean>,
          }),
          data => ({
            agreement: true,
            firstStepQuestions: option.some(data.firstQuestion),
            secondStepQuestions: option.some(data.secondQuestion),
          })
        )
      ),
      fieldValidators: () => ({
        agreement: validators.checked(
          formatMessage(
            "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.czCheckError"
          )
        ),
        firstStepQuestions: defined(),
        secondStepQuestions: defined(),
      }),
    },
    {
      onSubmit: values => {
        const data = values as ValidatedSolvencyFormData;
        const answers: api.InsuranceSolvencyAnswers = {
          firstQuestion: data.firstStepQuestions,
          secondQuestion: data.secondStepQuestions,
        };

        return pipe(
          currentFormState,
          option.fold(
            () => saveAnswers(answers),
            currentFormState =>
              pipe(
                areSameAnswers(answers, currentFormState.answers),
                boolean.fold(
                  () => saveAnswers(answers),
                  () => props.onSolvencyAnswered(currentFormState)
                )
              )
          )
        );
      },
    }
  );

  const resultBanner = () =>
    questionnaireResultInfo ? (
      <Banner
        content={resultInfoMessage}
        actions={option.none}
        title={option.none}
        type="success"
        onDismiss={option.none}
      />
    ) : (
      <Banner
        content={resultErrorMessage}
        actions={option.none}
        title={option.none}
        type="error"
      />
    );

  return (
    <Dialog
      variant="left"
      title={unsafeLocalizedString("")}
      actions={[]}
      size="large"
      onDismiss={option.some(props.onBack)}
    >
      <Stack column units={4}>
        <Stack units={6} column hAlignContent="center">
          <Body size="big" weight="medium">
            {formatMessage(
              "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.title"
            )}
          </Body>
          <Body size="medium" weight="regular">
            {formatMessage(
              "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.description"
            )}
          </Body>
        </Stack>
        <Space units={2} />
        {foldTenant(
          tenant,
          () => (
            <>
              <Body size="small" weight="regular">
                {formatMessage(
                  "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.sk.one"
                )}
              </Body>
              <UnorderedList listStyle="bullet" size="small">
                {[
                  formatMessage(
                    "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.sk.one.bulletPoint1"
                  ),
                  formatMessage(
                    "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.sk.one.bulletPoint2"
                  ),
                  formatMessage(
                    "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.sk.one.bulletPoint3"
                  ),
                  formatMessage(
                    "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.sk.one.bulletPoint4"
                  ),
                  formatMessage(
                    "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.sk.one.bulletPoint5"
                  ),
                  formatMessage(
                    "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.sk.one.bulletPoint6"
                  ),
                  formatMessage(
                    "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.sk.one.bulletPoint7"
                  ),
                ]}
              </UnorderedList>
              <Body size="small" weight="regular">
                {formatMessage(
                  "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.sk.two"
                )}
              </Body>
              <Body size="small" weight="regular">
                {formatMessage(
                  "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.sk.three"
                )}
              </Body>
              <Body size="small" weight="regular">
                {formatMessage(
                  "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.sk.four"
                )}
              </Body>
              <Body size="small" weight="regular">
                {formatMessage(
                  "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.sk.five"
                )}
              </Body>
              <Space units={4} />
              <FormSection>
                <FormRow type="full">
                  <YesNoRadioGroupField
                    {...fieldProps("firstStepQuestions")}
                    label={formatMessage(
                      "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.questionsOneFour"
                    )}
                  />
                </FormRow>
                <FormRow type="full">
                  <YesNoRadioGroupField
                    {...fieldProps("secondStepQuestions")}
                    label={formatMessage(
                      "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.questionFive"
                    )}
                  />
                </FormRow>
              </FormSection>
            </>
          ),
          () => (
            <>
              <Body size="small" weight="regular">
                {formatMessage(
                  "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.one"
                )}
              </Body>
              <Body size="small" weight="regular">
                {formatMessage(
                  "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.two"
                )}
              </Body>
              <Body size="small" weight="regular">
                {formatMessage(
                  "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.three"
                )}
              </Body>
              <Body size="small" weight="regular">
                {formatMessage(
                  "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.Questionnaire.four"
                )}
              </Body>
              <Space units={4} />
              <Box column>
                <FormRow type="full">
                  <YesNoRadioGroupField
                    {...fieldProps("firstStepQuestions")}
                    label={formatMessage(
                      "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.questionsOneThree"
                    )}
                  />
                </FormRow>
                <FormRow type="full">
                  <YesNoRadioGroupField
                    {...fieldProps("secondStepQuestions")}
                    label={formatMessage(
                      "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.questionFour"
                    )}
                  />
                </FormRow>
                <FormRow type="full">
                  <CheckboxField
                    {...fieldProps("agreement")}
                    label={formatMessage(
                      "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.agreement"
                    )}
                    description={formatMessage(
                      "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.agreementTooltip",
                      {
                        link: formatMessage(
                          "StandardLoan.CustomerOffer.CPIDialog.InsuranceSolvency.agreement.url"
                        ),
                      }
                    )}
                    multiline
                  />
                </FormRow>
              </Box>
            </>
          )
        )}
        {option.isSome(currentFormState) &&
          questionnaireResult &&
          resultBanner()}
        <Stack units={4} hAlignContent="right">
          <Button
            variant="link"
            label={formatMessage("CancelButton")}
            action={props.onBack}
          />
          <Button
            variant="secondary"
            size="default"
            label={formatMessage("Continue")}
            action={handleSubmit}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
}
